import React, { useEffect, useState } from 'react'
import { QueryClient } from '@tanstack/react-query'

import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import MainBanner from '../components/MainBanner/MainBanner';
import Services from '../components/Services/Services'
import spinner from '../assets/img/spinner.svg';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


const Homepage = ({ useStickyState, isBlackChecked, themeBlack, changeTheme }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const queryClient = new QueryClient();

    const token = JSON.parse(localStorage.getItem("token"));
    const navigate = useNavigate();

    useEffect(() => {
        const getMenu = async () => {
            try {
            } catch (error) {
                console.log("fetching error")
            }
        }

        if (token) {
            getMenu()
        } else {
            navigate('/auth');
        }

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/main/menu`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                });
                if (data) {
                    console.log("data", data);
                    setItems(data.sections)

                } else {
                    console.log("Данные не получены")
                }

            } catch (error) {
                console.log('error')
            }

            setLoading(false)
        }

        const fetchPermissions = async () => {
            try {
                const { data } = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/user`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                });

                if (data) {
                    let arrPermissions = [];
                    let arrRoles = [];

                    data.permissions.map((item) => {
                        arrPermissions.push(item.name)
                    })
                    data.roles.map((item) => {
                        arrRoles.push(item.name)
                    })

                    window.localStorage.setItem('arrRoles', JSON.stringify(arrRoles));
                    window.localStorage.setItem('arrPermissions', JSON.stringify(arrPermissions));
                }
            } catch (error) {
                console.log('Срок действия токена истёк или токен неправильный');
                navigate('/auth');
            }
        }

        if (token) {
            fetchData();
            fetchPermissions();
        } else {
            navigate('/auth');
        }

    }, [])

    return (
        <div>
            <Helmet>
                <title>Полезные ссылки</title>
            </Helmet>
            <MainBanner />
            {loading && <img className="loading" src={spinner} alt="Spinner" />}

            <div className="services-box">
                {items ? items.map(item => (
                    <Services title={item.header} items={item.items} key={item.header} />
                )) : <h2>Элементы не найдены</h2>}
            </div>
        </div>
    )
}

export default Homepage