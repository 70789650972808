import React, { useContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './App.scss';
import CreateDocument from './pages/CreateDocument/CreateDocument';
import Signature from './components/Signature/Signature';
import EditNameUser from './pages/EditNameUser/EditNameUser';
import ExternalPayment from './pages/ExternalPayment/ExternalPayment';
import FirstCount from './pages/FirstCount/FirstCount';
import ImportInCollab from './pages/ImportInCollab/ImportInCollab';
import MoveTimeFromProject from './pages/MoveTimeFromProject/MoveTimeFromProject';
import MoveTimeProject from './pages/MoveTimeProject/MoveTimeProject';
import NominateProduct from './pages/NominateProduct/NominateProduct';
import PlanResult from './pages/PlanResult/PlanResult';
import ReportsErrors from './pages/ReportsErrors/ReportsErrors';
import SupportReports from './pages/SupportReports/SupportReports';
import UnloadingData from './pages/UnloadingData/UnloadingData';
import UnloadTime from './pages/UnloadTime/UnloadTime';
import AssignProduct from './pages/AssignProduct/AssignProduct';
import Payments from './pages/Payments/Payments';
import Receipts from './pages/Receipts/Receipts';
import Acts from './pages/Acts/Acts';
import Layout from "./components/Layout";
import { Navigate, Route, Routes, Outlet, useNavigate } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Notfoundpage from "./pages/Notfoundpage";
import Auth from "./pages/Auth/Auth";
import AuthWrapper from "./pages/AuthWrapper/AuthWrapper";
import IncomingCalls from "./pages/IncomingCalls/IncomingCalls";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { role } from "./components/Roles";
import NoAccessPage from "./pages/NoAccessPage";
import NonDevPayments from "./pages/NonDevPayments/NonDevPayments";
import PlanRd from "./pages/Plans/PlanRd";
import PlanDc from "./pages/Plans/PlanDc";

const queryClient = new QueryClient();

function App(props) {
  const isLoggedIn = false;

  const ProtectedRoute = ({ userPermissions }) => {
    let arrPermissions = localStorage.getItem("arrPermissions");
    console.log(arrPermissions);
    if (arrPermissions && arrPermissions.indexOf(userPermissions) >= 0) {
      return <Outlet />;
    } else {
      return <Navigate to={'/no-access'} replace />
    }
  }

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <Routes basename="/React">
        <Route element={<Layout />}>
          <Route path="/" element={<App />} />
          <Route path="/auth" element={<Auth />} />
          <Route element={<AuthWrapper />}>
            <Route index element={<Homepage />} />
            {/* <Route showRoute={isLoggedIn} path="/signature" element={<Signature />} /> */}
            <Route path="/time-records-import" element={<ImportInCollab />} />
            <Route element={<ProtectedRoute userPermissions={'Менеджерские страницы'} />}>
              <Route path="/documents-create" element={<CreateDocument />} />
              <Route path="/first-payment" element={<FirstCount />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/receipts" element={<Receipts />} />
              <Route path="/acts" element={<Acts />} />
              <Route path="/external-payment" element={<ExternalPayment />} />
              <Route path="/reports" element={<SupportReports />} />
              <Route path="/specialist-errors" element={<ReportsErrors />} />
              <Route path="/export-collab-to-docs" element={<UnloadingData />} />
              <Route path="/set-product-and-stage-cloud" element={<NominateProduct />} />
              <Route path="/time-records-update-db" element={<UnloadTime />} />
              <Route roles={role.headOfDC} path="/plan-result" element={<PlanResult />} />
              <Route path="/nondev-payments" element={<NonDevPayments />} />
              <Route path="/plan-rd" element={<PlanRd />} />
              <Route path="/plan-dc" element={<PlanDc />} />
            </Route>
            <Route element={<ProtectedRoute userPermissions={'Пользовательские страницы'} />}>
              <Route path="/incoming-call" element={<IncomingCalls />} />
              <Route path="/signature" element={<Signature />} />
            </Route>
            <Route element={<ProtectedRoute userPermissions={'Страницы для администрирования'} />}>
              <Route path="/time-records-move-to-another-project" element={<MoveTimeFromProject />} />
              <Route path="/time-records-update-user-name" element={<EditNameUser />} />
              <Route path="/move-project-time" element={<MoveTimeProject />} />
            </Route>
            <Route path="/asign-product" element={<AssignProduct />} />
            <Route exact path="/404" element={<Notfoundpage />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
            <Route path="/no-access" element={<NoAccessPage />} />
          </Route>
        </Route>
      </Routes>
      <ReactQueryDevtools  />
    </QueryClientProvider>
  );
}

export default App;
