import React, {useState}  from 'react'
import close from '../../assets/img/close.svg'

function InputFile(props) {
	const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);
    const isButton = props.button || false;

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleUploadFiles = async(files) => {
        const uploaded = [...uploadedFiles];
        files.map((file) => {
            let fileData = {
                content: '',
                name: ''
            };
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                fileData.name = file.name;
                toBase64(file).then((resp) => {
                    fileData.content = resp
                        .replace('data:', '')
                        .replace(/^.+,/, '');
                    setUploadedFiles(prev => ([...prev, fileData]));
                });
            }
        })
    }

    function handleFileEvent(e) {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        if (isButton) {
            setUploadedFiles([]);
        }
        handleUploadFiles(chosenFiles);
    }

    function deleteFileApp(event) {
        const target = event.target.closest('.uploaded-files-list__item-remove');
        setUploadedFiles(uploadedFiles.filter((e) => e.name !== target.id));
    }

    return (
        <>
            { !isButton &&
                <>
                    <label className='input-file'>
                        <input type="file" id='file_id' name="files-input" multiple={props.multiple || false} onChange={handleFileEvent}/>
                        <span>Прикрепить файлы +</span>
                    </label>
                    <div className="uploaded-files-list">
                        {uploadedFiles.map(file => (
                            <div key={file.name} className="uploaded-files-list__item">
                                {file.name}
                                <div className='uploaded-files-list__item-remove' id={file.name} onClick={deleteFileApp}>
                                    <img src={close} alt="X" />
                                </div>

                                {/* префикс uplfiles_ нужен для корректной обработки при отправке формы
                                    Создание документа  */}
                                <input type="hidden" name={"uplfiles_" + file.name} value={file.content}/>
                            </div>
                        ))}
                    </div>
                </>
            }
            { isButton &&
                <>
                    <div className="input-file-wrap">
                        <label className="input-file">
                            <input type="file" id="file_id" name="files-input" onChange={handleFileEvent} multiple={false}/>
                            <span className="btn-white">Прикрепить файл</span>
                        </label>

                        {uploadedFiles.map(file => (
                            <b key={file.name}>
                                <span>{file.name}</span>
                                <i className='uploaded-files-list__item-remove' onClick={deleteFileApp} id={file.name}><img src={close} alt="X" /></i>
                                <input type="hidden" data-name={props.name} name={"uplfiles_" + file.name} value={file.content}/>
                            </b>
                        ))}
                    </div>
                </>
            }
        </>
    )
}

export default InputFile