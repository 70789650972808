import { React, useEffect, useState } from 'react'
import styles from './Nav.scss'
import arrBottom from '../../assets/img/arr-bottom.svg'
import arrBottomBlack from '../../assets/img/arr-bottom-black.svg'
import arrBottomGreen from '../../assets/img/arr-bottom-green.svg'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios'

const Nav = ({ props, userInfo, rolesList, permissionsList }) => {

    const token = JSON.parse(localStorage.getItem("token"));
    const navigate = useNavigate();
    const [menu, setMenu] = useState([]);
    const permissions = [];

    const [managersPages, setManagersPages] = useState();
    const [usersPages, setUsersPages] = useState();
    const [adminsPages, setAdminsPages] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/header`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                });
                if (data) {
                    console.log("data", data);
                    setMenu(data);
                } else {
                    console.log("Данные не получены");
                }

            } catch (error) {
                console.log('error');
            }
        }
        if (token) {
            fetchData();
        } else {
            navigate('/auth');
        }
    }, [])

    return (
        <ul className="menu">
            {menu.map((item, index) =>
                <li key={item.id}>
                    <Link to={item.href}>{item.header}
                        <i>
                            <img src={arrBottom} alt="" />
                            <img src={arrBottomBlack} alt="" />
                            <img src={arrBottomGreen} alt="" />
                        </i>
                    </Link>

                    {item.items ?
                        <ul className="sub-menu">
                            {item.items.map((itm, idx) =>
                                itm.href.includes('https') ?
                                    <li key={itm.name}>
                                        <a target="_blank" href={itm.href}>{itm.name}</a>
                                    </li> :
                                    <li key={itm.name}>
                                        <Link to={itm.href}>{itm.name}</Link>
                                    </li>
                            )}
                        </ul> : ''
                    }
                </li>
            )}
        </ul>
    )
}

export default Nav