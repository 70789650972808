import { React, useState, useEffect } from "react"
import ReactSelect from "react-select";
import DatalistInput from 'react-datalist-input';
import { Helmet } from "react-helmet";
import axios from "axios"

import UseCreationData from "../../api/UseCreationData"

import RadioBtnsBlock from "../../components/RadioBtnsBlock/RadioBtnsBlock";
import LabelCheckbox from "../../components/LabelCheckbox/LabelCheckbox";
import LabelRadio from "../../components/LabelRadio/LabelRadio";
import LabelRadioBtn from "../../components/LabelRadioBtn/LabelRadioBtn";
import Datepicker from "../../components/Datepicker/Datepicker";
import InputFile from "../../components/InputFile/InputFile";
import Application from "../../components/Application/Application";
import Stage from "../../components/Stage/Stage";
import close from "../../assets/img/close.svg"
import calendar from "../../assets/img/calendar.svg"
import spinner from '../../assets/img/spinner.svg';
import styles from "./CreateDocument.scss"

const token = JSON.parse(localStorage.getItem("token"));

const CreateDocument = ({ changeTheme, themeBlack }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [taskLink, setTaskLink] = useState("#");
    const [taskTitle, setTaskTitle] = useState("#");

    // общий объект, в который кладем данные от сервера
    const {creationData: data, creationDataStatus: status} = UseCreationData();
    // const [data, setData] = useState({});

    // общий объект, в который складываются ответы на вопросы формы
    const [input, setInput] = useState({});

    // Данные из файла
    const [department, setDepartment] = useState({});
    const [applications, setApplications] = useState({});
    const [applicationsDepartment, setApplicationsDepartment] = useState({});
    const [taskForm, setTaskForm] = useState({});
    const [contractors, setContractors] = useState([]);
    const [actionType, setActionType] = useState({});
    const [actionTypesList, setActionTypesList] = useState({});
    const [actionData, setActionData] = useState({});
    const [subscribers, setSubscribers] = useState({});
    const [subscribersList, setSubscribersList] = useState({});
    const [actionDataAt3, setActionDataAt3] = useState({});
    const [actionDataAt5, setActionDataAt5] = useState({});
    const [actionDataAt9, setActionDataAt9] = useState({});
    const [actionDataAt10, setActionDataAt10] = useState({});
    const [actionDataAt11, setActionDataAt11] = useState({});
    const [invoiseApplicationsDepartment, setInvoiseApplicationsDepartment] = useState({});
    const [actionDataAt12, setActionDataAt12] = useState({});

    useEffect(() => {
        document.title = "Форма заявки на создание документа";

        console.log("dataQ", data);
        if (data) {
            setIsLoaded(true);
            setDepartment(data["action-data"].at1.apps.department);
            setApplications(data["action-data"].at1.apps);
            setApplicationsDepartment({
                'development': data["action-data"].at1.apps['application-subject-development'].options,
                'digital': data["action-data"].at1.apps['application-subject-digital'].options
            });
            setTaskForm(data["action-data"].at2.apps["task-form"]);
            setContractors(Object.entries(data["contractor-name"].options).map(([key, item]) => ({ id: key, value: item.title, label: item.title })));
            setActionType(data["action-type"]);
            setActionTypesList(Object.entries(data["action-type"].options).map(([key, item]) => ({ id: key, title: item.title, label: item.title })));
            setActionData(data["action-data"]);
            setSubscribers(data["action-data"].at3["subscribers"]);
            setSubscribersList(Object.entries(data["action-data"].at3["subscribers"].list)
                .map(([key, item]) => ({ id: item.id, value: item.id, label: item.first_name + " " + item.last_name })));
            setActionDataAt3(data["action-data"].at3);
            setActionDataAt5(data["action-data"].at5);
            setActionDataAt9(data["action-data"].at9);
            setActionDataAt10(data["action-data"].at10);
            setActionDataAt11(data["action-data"].at11);
            setInvoiseApplicationsDepartment({
                'development': data["action-data"].at11['invoice-offer-client-subject-development'].options,
                'digital': data["action-data"].at11['invoice-offer-client-subject-digital'].options
            })
            setActionDataAt12(data["action-data"].at12);
        }
    }, [data])

    function setErrors(value, name) {
        const item = document.querySelector(`[name="${name}"]`);

        if (item && item.closest('[data-required="true"]')) {
            const elementError = document.createElement("div");
            elementError.innerHTML = 'Не заполнено поле';
            elementError.classList.add('ajax-error');

            if (!value) {
                item.closest('.content-form-item').prepend(elementError);
            } else {
                item.closest('.content-form-item').querySelectorAll('.ajax-error').forEach(e => e.remove());
            }
        }
    }

    // Приложения (Новый договор, приложение)
    const [appsArray, setAppsArray] = useState([""]);
    function applicationInput(name, value) {
        setInput(prev => ({ ...prev, [name]: value }));
    }
    function addApp(index) {
        setAppsArray([...appsArray, index.target.value]);
    }
    function removeApp(index) {
        setAppsArray([...appsArray.slice(0, index), ...appsArray.slice(index + 1)]);
    }
    const resultApps = appsArray.map((element, index) => {
        return <Application id = {index+1}
                    remove = {removeApp}
                    input = {input}
                    department = {department}
                    applications = {applications}
                    applicationsDepartment = {applicationsDepartment}
                    taskForm = {taskForm}
                    showLabelRadio = {showLabelRadio}
                    showLabelRadioBtn = {showLabelRadioBtn}
                    showLabelCheckbox = {showLabelCheckbox}
                    changeLabelRadio = {changeLabelRadio}
                    chgDate = {chgDate}
                    applicationInput = {applicationInput}
                    applicationsInGroups = {applicationsInGroups}
                    showApplicationsGroups = {showApplicationsGroups}
                    setErrors = {setErrors}
                />;
    });

    function chgDate(date, name, inputName) {
        setErrors(date, name)
        setInput(prev => ({ ...prev, [inputName]: date }));
    }


    // Записываем отмеченные чекбоксы в общий объект данных
    function changeCheckbox(event, nameRadio) {
        let checkedInputs = input[nameRadio] || [];

        if (event.target.checked) {
            checkedInputs.push(event.target.value);
        } else {
            checkedInputs = checkedInputs.filter((e) => e !== event.target.value);
        }
        setInput(prev => ({ ...prev, [nameRadio]: checkedInputs }));
    }

    // Отображение чекбоксов
    function showLabelCheckbox(obj, nameEl, inputName) {
        return Object.keys(obj.options).map((key) => {
            return <LabelCheckbox key={key} changeLabelCheckbox={(e) => (changeCheckbox(e, inputName))}
                name={nameEl} value={obj.options[key].id} title={obj.options[key].title}
            />
        })
    }

    // Записываем выбранное значение инпута в общий объект данных
    function changeLabelRadio(nameRadio, valueRadio, idRadio) {
        setInput(prev => ({ ...prev, [nameRadio]: valueRadio, [nameRadio + "Id"]: idRadio }));
    }

    // Отображение радиокнопок вида кружок + название
    function showLabelRadio(obj, nameEl, inputName) {
        return Object.keys(obj.options).map((key) => {
            return <LabelRadio key={key} changeLabelRadio={() => (changeLabelRadio(inputName, obj.options[key].title, obj.options[key].id))}
                name={nameEl} value={obj.options[key].id} title={obj.options[key].title}
            />
        })
    }

    // Отображение радиокнопок вида а-ля кнопка с названием
    function showLabelRadioBtn(obj, nameEl, inputName) {
        return Object.keys(obj.options).map((key) => {
            if (input.contractSigner && input.contractSigner != null && input.contractSigner != "" && input.contractSignerId === 'cs2'
                && obj.options[key].id === 'marketing') {
                return false;
            }
            return <LabelRadioBtn key={key} changeLabelRadioBtn={() => (changeLabelRadio(inputName, obj.options[key].title, obj.options[key].id))}
                name={nameEl} value={obj.options[key].id} title={obj.options[key].title}
            />
        })
    }

    // если договор уже есть, ищем информацию о нем
    // выводим селект, если данные есть; инпут, если данных нет
    function showContractNumberInput(contractor) {
        const contractNumber = data["contract-number"];
        const contracts = contractNumber.options[contractor] ? contractNumber.options[contractor].contracts : null;

        if (contracts && Object.keys(contracts) && Object.keys(contracts).length > 0) {
            const contractsArray = Object.entries(contracts).map(([key, item]) => ({ id: key, value: key, label: item }));
            return (
                <div className="content-select">
                    <ReactSelect onChange={(choice) => setInput(prev => ({ ...prev, contractsArray: choice.id }))}
                        options={contractsArray} name="contract-number" className="react-select-container"
                        classNamePrefix="react-select" placeholder="Введите номер договора" />
                </div>
            )
        } else {
            return <input type="text" name="contract-number" placeholder="Введите номер договора" />
        }
    }

    // собираем предметы договора по группам
    function applicationsInGroups(apps) {
        let result = {};
        for (let key in apps) {
            let groupName = apps[key]['group_name'];

            if (!result[groupName]) {
                result[groupName] = {};
                result[groupName].items = [];
                result[groupName].title = groupName;
            }
            result[groupName].items.push(apps[key]);
        }

        return result;
    }
    // выводим Предмет приложения по группам или без
    function showApplicationsGroups(apps, groups, department, actionData, appsPrefix = '', inputDataName = 'applicationSubject') {
        let inputNamePrefix = '';

        if (actionData.indexOf('invoice') >= 0) {
            // for at11
            inputNamePrefix = 'ad_';
        }

        if (Object.keys(groups).length > 1) {
            return Object.keys(groups).map((keyGr) => {
                return (
                    <RadioBtnsBlock chgTypeWorks={(e) => changeLabelRadio(inputDataName, e.target.dataset.title, e.target.value)}
                        nameForInput={`${appsPrefix}${inputNamePrefix}${actionData}-${department}`}
                        items={groups[keyGr]}
                        key={keyGr.title} />
                )
            })
        } else {
            return showLabelRadio(apps[`${actionData}-${department}`], `${appsPrefix}${inputNamePrefix}${actionData}-${department}`, inputDataName)
        }
    }


    function validateForm(form) {
        const formFields = form.querySelectorAll(`input, textarea, select`);
        const elementError = document.createElement("div");
        elementError.innerHTML = 'Не заполнено поле';
        elementError.classList.add('ajax-error');

        formFields.forEach((item) => {
            let itemName = item.getAttribute('name');

            if (item.closest('[data-required="true"]') && itemName !== 'files-input') {
                item.closest('.content-form-item').querySelectorAll('.ajax-error').forEach(e => e.remove());

                if (item.getAttribute('type') === 'radio' || item.getAttribute('type') === 'checkbox') {
                    if (!form.querySelector(`[name="${itemName}"]:checked`)) {
                        item.closest('.content-form-item').prepend(elementError);
                    }
                } else {
                    if ((item.value === '' || item.value.length === 0) && !item.closest('._hidden')) {
                        // if (itemName === 'ad_links') {
                        //     console.log(item.closest('[data-required="true"]').querySelector('.uploaded-files-list__item'));
                        // }
                        item.closest('.content-form-item').prepend(elementError);
                    }
                }
            }
        });

        const errorMessages = form.querySelectorAll(`.ajax-error`);
        if (errorMessages.length > 0) {
            window.scrollTo({ top: form.querySelectorAll(`.ajax-error`)[0].offsetTop, behavior: 'smooth' });
            return false;
        }

        return true;
    }

    function handleBackToForm() {
        setIsSubmitted(false);
    }

    // отправка формы
    function submitForm(e) {
        e.preventDefault();
        const form = e.target;
        form.querySelectorAll('.ajax-error').forEach(e => e.remove());

        if (!validateForm(form)) {
            return false;
        }

        setIsLoading(true);

        const formData = new FormData(form);
        const formDataObj = {};
        formData.forEach((value, key) => {
            // игнорируем инпут для загрузки файлов
            if (key === 'files-input') return;

            // собираем файлы в массив объектов
            if (key.indexOf('uplfiles') >= 0) {
                const fileName = key.replace('uplfiles_', '');
                if (!formDataObj['ad_files']) {
                    formDataObj['ad_files'] = [{
                        'file-name': fileName,
                        'content': value
                    }];
                } else {
                    formDataObj['ad_files'].push({
                        'file-name': fileName,
                        'content': value
                    });
                }

                // собираем данные от чекбоксов в массив
            } else if (key.indexOf('[]') >= 0) {
                key = key.replace('[]', '');
                if (!formDataObj[key]) {
                    formDataObj[key] = [value];
                } else {
                    formDataObj[key].push(value);
                }
            } else {
                formDataObj[key] = value;
            }

        });

        // собираем данные по action-data в один объект
        for (let key in formDataObj) {
            let keyArray = [];
            if (!Object.keys(formDataObj).includes('action-data')) {
                formDataObj['action-data'] = {};
            }
            if (key.indexOf('ad_') >= 0) {
                keyArray = key.split('_');
                formDataObj['action-data'][keyArray[1]] = formDataObj[key];

                delete formDataObj[key];
            }
        }

        // собираем данные по Приложения в один массив apps
        for (let key in formDataObj) {
            if (!Object.keys(formDataObj).includes('action-data')) {
                formDataObj['action-data'] = {};
            }
            if (key.indexOf('apps') >= 0) {
                if (!Object.keys(formDataObj['action-data']).includes('apps')) {
                    formDataObj['action-data'].apps = [];
                }

                const keyArray = key.split('_');
                const appNumber = keyArray[0].split('-')[1];

                if (!formDataObj['action-data'].apps[appNumber - 1]) {
                    formDataObj['action-data'].apps[appNumber - 1] = {};
                }

                // если инпут не из этапа
                if (keyArray[1].indexOf('stage') < 0) {
                    const dataName = keyArray[1];
                    formDataObj['action-data'].apps[appNumber - 1][dataName] = formDataObj[key];
                } else {
                    // собираем данные по Этапам для каждого Приложения в один массив stages
                    if (!Object.keys(formDataObj['action-data'].apps[appNumber - 1]).includes('stages')) {
                        formDataObj['action-data'].apps[appNumber - 1].stages = [];
                    }
                    const stageNumber = keyArray[1].split('-')[1];
                    const dataName = keyArray[2];

                    if (!formDataObj['action-data'].apps[appNumber - 1].stages[stageNumber-1]) {
                        formDataObj['action-data'].apps[appNumber - 1].stages[stageNumber-1] = {};
                    }
                    formDataObj['action-data'].apps[appNumber - 1].stages[stageNumber-1][dataName] = formDataObj[key];
                }
                delete formDataObj[key];
            }
        }

        // обработка массива с подписчиками
        if (formDataObj['action-data'].subscribers && formDataObj['action-data'].subscribers.length === 1
            && formDataObj['action-data'].subscribers[0] === '') {
            formDataObj['action-data'].subscribers = [];
        } else {
            const tempSubscr = formDataObj['action-data'].subscribers.map(el => {
                return parseInt(el, 10);
            });
            formDataObj['action-data'].subscribers = tempSubscr;
        }

        console.log('formDataObj', formDataObj);

        axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL}/v1/documents/create`,
            // url: "../ajax/ex-payment.json",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + token,
            },
            data: formDataObj,
        })
            .then(function (response) {
                console.log('Response: ', response);
                setIsLoading(false);
                setIsSubmitted(true);
                if (response.data.result) {
                    response.data.task_url && setTaskLink(response.data.task_url);
                    response.data.task_name && setTaskTitle(response.data.task_name);
                    setInput({});
                    setAppsArray([""]);
                    document.querySelectorAll('input[name="timing"]').forEach(el => el.checked = false);
                }
            })
            .catch(function (response) {
                console.error('Response: ', response);
                console.error('Error: ', response.response.data.error);
                setIsLoading(false);

                const responseError = response.response.data.error;
                const elementError = document.createElement("div");

                if (response.response.status === 422) {
                    Object.keys(responseError).map((key) => {
                        let errorName = key;
                        if (key.indexOf('action-data.') >= 0) {
                            let temp = key.split('.');
                            errorName = 'ad_' + temp[temp.length - 1];
                        }

                        elementError.innerHTML = responseError[key][0];
                        elementError.classList.add('ajax-error');

                        const item = form.querySelectorAll(`[name="${errorName}"]`);
                        if (item[0] && item[0].closest('[data-required="true"]')) {
                            item[0].closest('.content-form-item').prepend(elementError);
                        } else {
                            form.querySelector(`.submit-block`).prepend(elementError);
                        }
                    })
                } else {
                    elementError.innerHTML = responseError;
                    elementError.classList.add('ajax-error');
                    form.querySelector(`.submit-block`).prepend(elementError);
                }

                const errorMessages = form.querySelectorAll(`.ajax-error`);
                if (errorMessages.length > 0) {
                    window.scrollTo({ top: form.querySelectorAll(`.ajax-error`)[0].offsetTop, behavior: 'smooth' });
                }
            });
    }

    // пока не загружены данные выводится заглушка
    if (!isLoaded || status === "loading") {
        return (
            <div>
                <div className="content content-create-document">
                    <div className="container">
                        <img className="loading" src={spinner} alt="Spinner" />
                    </div>
                </div>
            </div>
        )
    }

    // префикс "ad_" нужен элементам формы для того, чтобы при отправке данные из этих элементов собирались в объект "action-data"
    return (
        <div>
            { status === "error" ? (
                <p>Error</p>
            ) : (
                <>
                    <Helmet>
                        <title>Форма заявки на создание документа</title>
                    </Helmet>

                    <div className="content content-create-document">
                        <div className="container">
                            <h1>Форма заявки на создание документа</h1>
                            <div className="desc">Все поля помеченные <i>*</i> обязательны для заполнения</div>
                            {isSubmitted &&
                                <div className="create-document__result">
                                    <h2>Заявка успешно создана</h2>
                                    <h3><a href={taskLink}>{taskTitle}</a></h3>
                                    <a href="/" className="btn-white">Вернуться на главную</a>
                                    <div className='fake-link' onClick={handleBackToForm}>Создать еще заявку</div>
                                </div>
                            }

                            {!isSubmitted &&
                                <form className="document-form" onSubmit={submitForm}>
                                    {/* Насколько срочно? */}
                                    <div className="content-form-item">
                                        <div className="content-form-item-desc">
                                            <label htmlFor="">{data.timing.title} {data.timing.required && <i>*</i>}</label>
                                        </div>
                                        <div className="content-form-item-right" data-required={data.timing.required ? true : false}>
                                            {showLabelRadio(data.timing, "timing", "timing")}
                                        </div>
                                    </div>
                                    <hr />

                                    {   //Кто мы по договору?
                                        input.timing != null && input.timing != "" &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{data['contract-signer'].title} {data['contract-signer'].required && <i>*</i>}</label>
                                                </div>

                                                <div className="label-radio-btns-wrap" data-required={data['contract-signer'].required ? true : false}>
                                                    {showLabelRadioBtn(data['contract-signer'], "contract-signer", "contractSigner")}
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Название контрагента / проекта
                                        input.contractSigner != null && input.contractSigner != "" &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{data['contractor-name'].title} {data['contractor-name'].required && <i>*</i>}</label>
                                                </div>

                                                <div className="content-select" data-required={data['contractor-name'].required ? true : false}>
                                                    <DatalistInput className='react-select-container datalist-input datalist-input-contract'
                                                        items={contractors} placeholder="Введите название" inputProps={{ name: "contractor-name" }}
                                                        onChange={(e) => setInput(prev => ({ ...prev, contractorName: e.target.value }))}
                                                        onSelect={(choice) => setInput(prev => ({ ...prev, contractorName: choice.id }))} />
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Договор с контрагентом уже есть? + Номер договора
                                        input.contractorName != null && input.contractorName != "" &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{data['contract-exists'].title} {data['contract-exists'].required && <i>*</i>}</label>
                                                </div>
                                                <div className="content-form-item-right" data-required={data['contract-exists'].required ? true : false}>
                                                    {showLabelRadio(data['contract-exists'], "contract-exists", "contractExists")}

                                                    {
                                                        input.contractExists === "Да" && showContractNumberInput(input.contractorName)
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Юридическое лицо с нашей стороны?
                                        input.contractExists != null && input.contractExists != "" &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{data["legal-entity"].title} {data["legal-entity"].required && <i>*</i>}</label>
                                                </div>

                                                <div className="label-radio-btns-wrap">
                                                    {showLabelRadioBtn(data["legal-entity"], "legal-entity", "legalEntity")}
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }
                                    {/* TODO: добавить отслеживание изменения способа подписания и даты */}
                                    {   //Каким образом подписываем документы?
                                        input.legalEntity != null && input.legalEntity != "" &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{data["how-sign"].title} {data["how-sign"].required && <i>*</i>}</label>
                                                </div>

                                                <div className="label-radio-btns-wrap" data-required={data["how-sign"].required ? true : false}>
                                                    {showLabelRadioBtn(data["how-sign"], "how-sign", "howSign")}
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //От какого числа делать документ?
                                        input.howSign != null && input.howSign != "" &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{data['contract-date'].title} {data['contract-date'].required && <i>*</i>}</label>
                                                </div>

                                                <div className="content-select" data-required={data["contract-date"].required ? true : false}>
                                                    <Datepicker name="contract-date" inputName="contractDate"
                                                        chgDate={chgDate} minDate={input.howSign === "Через ЭДО" ? true : false} />
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Начали ли уже работы?
                                        input.contractDate != null && input.contractDate != "" &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{data["work-already-begun"].title} {data["work-already-begun"].required && <i>*</i>}</label>
                                                </div>

                                                <div className="label-radio-btns-wrap" data-required={data["work-already-begun"].required ? true : false}>
                                                    {showLabelRadioBtn(data["work-already-begun"], "work-already-begun", "workAlreadyBegun")}
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Каким числом датировать ретрооговорку?
                                        input.workAlreadyBegun != null && input.workAlreadyBegun != "" && input.workAlreadyBegun === "Да" &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{data["date-retro-clause"].title} {data["date-retro-clause"].required && <i>*</i>}</label>
                                                    <p>Укажите реальную дату начала работ</p>
                                                </div>

                                                <div className="content-select" data-required={data["date-retro-clause"].required ? true : false}>
                                                    <Datepicker name="date-retro-clause" inputName="dateRealWork"
                                                        chgDate={chgDate} maxDate={new Date()} />
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Что делаем?
                                        ((input.dateRealWork != null && input.dateRealWork != "") ||
                                            (input.workAlreadyBegun != null && input.workAlreadyBegun != "" && input.workAlreadyBegun === "Нет"))
                                        &&
                                        <>
                                            <div className="content-form-item content-form-item--full-width">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{actionType.title} {actionType.required && <i>*</i>}</label>
                                                </div>
                                                <div className="whats-do-wrap">
                                                    <div className="whats-do-list" data-required={actionType.required ? true : false}>
                                                        {
                                                            actionTypesList.map(option => {
                                                                if (input.contractExists != "Да" && (option.id === "at2" || option.id === "at3")) {
                                                                    return false;
                                                                } else {
                                                                    return <LabelRadio key={option.id} changeLabelRadio={() => (changeLabelRadio("actionType", option.title, option.id))}
                                                                        name="action-type" value={option.id} title={option.title}
                                                                    />
                                                                }
                                                            })
                                                        }
                                                    </div>

                                                    {
                                                        (input.actionType === "Новый договор + приложение" || input.actionTypeId === "at1" ||
                                                            input.actionType === "Партнерский договор" || input.actionTypeId === "at9")
                                                        &&
                                                        <div className="whats-do-adds">
                                                            <div className="small-title">Контакты</div>
                                                            {/* <div className="small-title">{actionData[input.actionTypeId]["contact-person"].title}</div> */}
                                                            <input type="text" onChange={(e) => setInput(prev => ({ ...prev, contactPerson: e.target.value }))}
                                                                name="ad_contact-person" placeholder="ФИО" />
                                                            <input type="text" onChange={(e) => setInput(prev => ({ ...prev, contactPersonPosition: e.target.value }))}
                                                                name="ad_contact-person-position" placeholder="Должность" />
                                                            <input type="tel" onChange={(e) => setInput(prev => ({ ...prev, contactPersonPhone: e.target.value }))}
                                                                name="ad_contact-person-phone" placeholder="Телефон" />
                                                            <input type="text" onChange={(e) => setInput(prev => ({ ...prev, contactPersonEmail: e.target.value }))}
                                                                name="ad_contact-person-email" placeholder="Электронная почта" />
                                                            <br />
                                                            {
                                                                ((input.contractSigner === "Исполнитель" || input.contractSignerId === "cs2")
                                                                )
                                                                &&
                                                                <div data-required={data["contract-date"].required ? true : false}>
                                                                    <div className="small-title">Отрасль компании заказчика <i>*</i></div>
                                                                    <input type="text" onChange={(e) => setInput(prev => ({ ...prev, segmentName: e.target.value }))}
                                                                        name="ad_segment-name" placeholder="Недвижимость, FMCG, алкоголь, табак, фармацевтика и т.п." />
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        (input.actionType === "Счет-оферта для клиента" || input.actionTypeId === "at11")
                                                        &&
                                                        <div className="whats-do-adds">
                                                            <div className="small-title">Контакты <i>*</i></div>
                                                            {/* <div className="small-title">{actionData[input.actionTypeId]["contact-person"].title}</div> */}
                                                            <input type="text" onChange={(e) => setInput(prev => ({ ...prev, contactPerson: e.target.value }))}
                                                                name="ad_invoice-offer-client-fio" placeholder="ФИО" />
                                                            <input type="text" onChange={(e) => setInput(prev => ({ ...prev, contactPersonPosition: e.target.value }))}
                                                                name="ad_invoice-offer-client-position" placeholder="Должность" />
                                                            <input type="tel" onChange={(e) => setInput(prev => ({ ...prev, contactPersonPhone: e.target.value }))}
                                                                name="ad_invoice-offer-client-phone" placeholder="Телефон" />
                                                            <input type="text" onChange={(e) => setInput(prev => ({ ...prev, contactPersonEmail: e.target.value }))}
                                                                name="ad_invoice-offer-client-email" placeholder="Электронная почта" />
                                                        </div>
                                                    }

                                                    {
                                                        input.actionType === "NDA" || input.actionTypeId === "at4"
                                                        &&
                                                        <div className="whats-do-adds">
                                                            <div className="small-title">Реквизиты</div>
                                                            <textarea name="ad_links" placeholder={actionData[input.actionTypeId].links.title}></textarea>

                                                            <div className="sub-title">Карточка предприятия / уставные контрагента <br />
                                                                <InputFile name="ad_files" multiple={true} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                        </>

                                    }

                                    {   //Новый договор + приложение
                                        ((input.actionType === "Новый договор + приложение" || input.actionTypeId === "at1"
                                            || input.actionType === "Приложение (задание, заказ)") || input.actionTypeId === "at2")
                                        // && (input.contractSignerId === "cs1" || (input.contractSignerId === "cs2" && input.segmentName != null && input.segmentName != ""))
                                        &&
                                        <>
                                            {resultApps}

                                            { input[`paymentScheme${appsArray.length}`] != null && input[`paymentScheme${appsArray.length}`]  &&
                                                <>
                                                    <div className="add-application">
                                                        <button type="button" onClick={addApp} className="btn-white">Добавить приложение</button>
                                                    </div>
                                                    <hr />
                                                </>
                                            }
                                        </>
                                    }

                                    {   //Дополнительное соглашение
                                        (input.actionType === "Дополнительное соглашение (правка, продление, расторжение) к договору или приложению"
                                            || input.actionTypeId === "at3")
                                        &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{actionDataAt3["additional-agreement-target"].title} {actionDataAt3["additional-agreement-target"].required && <i>*</i>}</label>
                                                </div>
                                                <input type="text" name="ad_additional-agreement-target" placeholder="Введите название"
                                                    onChange={(e) => setInput((prev) => ({ ...prev, additionalAgreementTarget: e.target.value }))} />
                                            </div>
                                            <hr />

                                            {   //К какому документу делается дополнительное соглашение (далее - ДС)?
                                                input.additionalAgreementTarget != null && input.additionalAgreementTarget != "" &&
                                                <>
                                                    <div className="content-form-item content-form-item-row content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt3["additional-agreement-subject"].title} {actionDataAt3["additional-agreement-subject"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="form-item-adds" data-required={actionDataAt3["additional-agreement-subject"].required ? true : false}>
                                                            <div className="radio-btns">
                                                                {showLabelRadio(actionDataAt3["additional-agreement-subject"], "ad_additional-agreement-subject", "additionalAgreementSubject")}
                                                            </div>

                                                            <div className="whats-do-adds">
                                                                {   //Изменение/дополнение условий приложения
                                                                    (input.additionalAgreementSubject === "Изменение/дополнение условий приложения" || input.additionalAgreementSubjectId === "aas1")
                                                                    &&
                                                                    <>
                                                                        <div className="small-title">{actionDataAt3["additional-agreement-changes"].title} </div>
                                                                        <b>Необходимо указать:</b>
                                                                        <div dangerouslySetInnerHTML={{ __html: actionDataAt3["additional-agreement-changes"].hint }}></div>
                                                                        <textarea name="ad_additional-agreement-changes" placeholder="Введите изменения / дополнения"
                                                                            onChange={(e) => setInput((prev) => ({ ...prev, additionalAgreementChangesText: e.target.value }))} >
                                                                        </textarea>
                                                                    </>
                                                                }

                                                                {   //Расторжение приложения или Расторжение договора
                                                                    (input.additionalAgreementSubject === "Расторжение приложения" || input.additionalAgreementSubjectId === "aas2" ||
                                                                        input.additionalAgreementSubjectId === "aas3" || input.additionalAgreementSubject === "Расторжение договора")
                                                                    &&
                                                                    <>
                                                                        {/* Нами выполнены все работы/услуги по приложению(ям) к договору? */}
                                                                        <div className="small-title">{actionDataAt3["work-status"].title}  {actionDataAt3["work-status"].required && <i>*</i>}</div>
                                                                        <div className="label-radio-btns-wrap">
                                                                            {showLabelRadioBtn(actionDataAt3["work-status"], "ad_work-status", "workStatus")}
                                                                        </div>
                                                                        {
                                                                            input.workStatus === "Нет" &&
                                                                            <div className="add-input">
                                                                                <textarea onChange={(e) => setInput(prev => ({ ...prev, workStatusText: e.target.value }))}
                                                                                    className="textarea-small" name="ad_work-status-incomplete" placeholder="Выполненные и невыполненные работы (этапы работ) по приложению(ям)"></textarea>
                                                                            </div>
                                                                        }

                                                                        {   //Нами получена полностью вся оплата за выполненные работы?
                                                                            (input.workStatus != null && input.workStatus != "")
                                                                            &&
                                                                            <>
                                                                                <br />
                                                                                <div className="small-title">{actionDataAt3["payment-status"].title} {actionDataAt3["payment-status"].required && <i>*</i>}</div>
                                                                                <div className="label-radio-btns-wrap">
                                                                                    {showLabelRadio(actionDataAt3["payment-status"], "ad_payment-status", "paymentStatus")}
                                                                                </div>
                                                                                {
                                                                                    input.paymentStatus != null && input.paymentStatus != "" &&
                                                                                    input.paymentStatus != "Оплата получена полностью" && input.paymentStatusId != "ps1" &&
                                                                                    <div className="add-input">
                                                                                        <textarea onChange={(e) => setInput(prev => ({ ...prev, paymentStatusText: e.target.value }))}
                                                                                            className="textarea-small" name="ad_payment-status-incomplete"
                                                                                            placeholder="Номер приложения, сумма задолженности и до какого числа она должна быть оплачена">
                                                                                        </textarea>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }

                                                                        {   //У нас в наличии все акты по приложению(ям) к Договору?
                                                                            (input.paymentStatus != null && input.paymentStatus != "")
                                                                            &&
                                                                            <>
                                                                                <br />
                                                                                <div className="small-title">{actionDataAt3["acts-status"].title} {actionDataAt3["acts-status"].required && <i>*</i>}</div>
                                                                                <div className="label-radio-btns-wrap">
                                                                                    {showLabelRadioBtn(actionDataAt3["acts-status"], "ad_acts-status", "actsStatus")}
                                                                                </div>
                                                                                {
                                                                                    (input.actsStatus === "Нет" || input.actsStatusId === "as2") &&
                                                                                    <div className="add-input">
                                                                                        <textarea onChange={(e) => setInput(prev => ({ ...prev, actsStatusText: e.target.value }))}
                                                                                            className="textarea-small" name="ad_acts-status-incomplete"
                                                                                            placeholder="Причина отсутствия акта(ов), срок получения акта(ов)">
                                                                                        </textarea>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }

                                                                        {   //Есть ли дополнительные условия, которые надо прописать в расторжении?
                                                                            (input.actsStatus != null && input.actsStatus != "")
                                                                            &&
                                                                            <>
                                                                                <br />
                                                                                <div className="small-title">{actionDataAt3["additional-conditions"].title} {actionDataAt3["additional-conditions"].required && <i>*</i>}</div>
                                                                                <div className="label-radio-btns-wrap">
                                                                                    {showLabelRadioBtn(actionDataAt3["additional-conditions"], "ad_additional-conditions", "additionalConditions")}
                                                                                </div>
                                                                                {
                                                                                    (input.additionalConditions === "Да" || input.additionalConditionsId === "ac1") &&
                                                                                    <div className="add-input">
                                                                                        <textarea onChange={(e) => setInput(prev => ({ ...prev, additionalConditionsText: e.target.value }))}
                                                                                            className="textarea-small" name="ad_additional-conditions-text"
                                                                                            placeholder="Описание дополнительных условий">
                                                                                        </textarea>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                }

                                                                {   //Другое
                                                                    (input.additionalAgreementSubject === "Другое" || input.additionalAgreementSubjectId === "aas4")
                                                                    &&
                                                                    <>
                                                                        <input onChange={(e) => setInput(prev => ({ ...prev, additionalAgreementOther: e.target.value }))}
                                                                            name="ad_additional-agreement-other" type="text" placeholder="Предмет ДС" />
                                                                        <br />
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }
                                        </>
                                    }

                                    {   //Договор авторского заказа или приложение к нему
                                        (input.actionType === "Договор авторского заказа или приложение к нему" || input.actionTypeId === "at5")
                                        &&
                                        <>
                                            {/* Для какого отдела автор выполнял работу? */}
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{actionDataAt5["author-contract-department"].title} {actionDataAt5["author-contract-department"].required && <i>*</i>}</label>
                                                </div>

                                                <div className="label-radio-btns-wrap" data-required={actionDataAt5["author-contract-department"].required ? true : false}>
                                                    {showLabelRadioBtn(actionDataAt5["author-contract-department"], "ad_author-contract-department", "authorContractDepartment")}
                                                </div>
                                            </div>
                                            <hr />

                                            {   //Вид работ/услуг
                                                input.authorContractDepartment != null && input.authorContractDepartment != "" &&
                                                <>
                                                    <div className="content-form-item content-form-item-row content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt5["author-work-type"].title} {actionDataAt5["author-work-type"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="form-item-adds form-item-adds-flex-end" data-required={actionDataAt5["author-work-type"].required ? true : false}>
                                                            <div className="radio-btns">
                                                                {showLabelRadio(actionDataAt5["author-work-type"], "ad_author-work-type", "authorWorkType")}
                                                            </div>

                                                            {
                                                                input.authorWorkType === "Другое" &&
                                                                <div className="whats-do-adds">
                                                                    <textarea onChange={(e) => setInput(prev => ({ ...prev, authorWorkTypeText: e.target.value }))}
                                                                        className="textarea-small" name="ad_author-work-type-other"
                                                                        placeholder="Описание работ/услуг">
                                                                    </textarea>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Описание работ/услуг
                                                input.authorWorkType != null && input.authorWorkType != "" &&
                                                <>
                                                    <div className="content-form-item content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt5["work-volume"].title} {actionDataAt5["work-volume"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="textarea-block" data-required={actionDataAt5["work-volume"].required ? true : false}>
                                                            <div dangerouslySetInnerHTML={{ __html: actionDataAt5["work-volume"].hint }}></div>
                                                            <textarea onChange={(e) => setInput(prev => ({ ...prev, workVolume: e.target.value }))}
                                                                name="ad_work-volume" placeholder="">
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Срок сдачи работ нам
                                                input.workVolume != null && input.workVolume != "" &&
                                                <>
                                                    <div className="content-form-item">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt5["deadline-for-us"].title} {actionDataAt5["deadline-for-us"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="date-block" data-required={actionDataAt5["deadline-for-us"].required ? true : false}>
                                                            <Datepicker name="ad_deadline-for-us" chgDate={chgDate} inputName="deadlineForUs" />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Сумма оплаты автору по договору
                                                input.deadlineForUs != null && input.deadlineForUs != "" &&
                                                <>
                                                    <div className="content-form-item">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt5["payment-amount"].title} {actionDataAt5["payment-amount"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="content-form-item-right" data-required={actionDataAt5["payment-amount"].required ? true : false}>
                                                            <input type="text" name="ad_payment-amount" placeholder="Введите значение"
                                                                onChange={(e) => setInput(prev => ({ ...prev, paymentAmount: e.target.value }))} />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //До какого числа должна быть проведена оплата по договору?
                                                input.paymentAmount != null && input.paymentAmount != "" &&
                                                <>
                                                    <div className="content-form-item">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt5["payment-date"].title} {actionDataAt5["payment-date"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="date-block" data-required={actionDataAt5["payment-date"].required ? true : false}>
                                                            <Datepicker name="ad_payment-date" chgDate={chgDate} inputName="paymentDate" />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Электронная почта автора
                                                input.paymentDate != null && input.paymentDate != "" &&
                                                <>
                                                    <div className="content-form-item">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt5["author-email"].title} {actionDataAt5["author-email"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="content-form-item-right" data-required={actionDataAt5["author-email"].required ? true : false}>
                                                            <input type="text" name="ad_author-email" placeholder="Введите email"
                                                                onChange={(e) => setInput(prev => ({ ...prev, authorEmail: e.target.value }))} />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Дополнительные договоренности / комментарии
                                                input.authorEmail != null && input.authorEmail != ""
                                                &&
                                                <>
                                                    <div className="content-form-item content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt5["author-contract-comments"].title} {actionDataAt5["author-contract-comments"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="textarea-block" data-required={actionDataAt5["author-contract-comments"].required ? true : false}>
                                                            <textarea name="ad_author-contract-comments" placeholder="Введите сообщение…"
                                                                onChange={(e) => setInput((prev) => ({ ...prev, authorContractComments: e.target.value }))} >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }
                                        </>
                                    }

                                    {   //Только поле для описания - Договор ГПХ
                                        (input.actionType === "Договор ГПХ (для физ. лиц, оказание услуг без создания РИД)" || input.actionTypeId === "at6")
                                        &&
                                        <>
                                            <div className="content-form-item content-form-item--full-width">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">Описание документа:</label>
                                                </div>

                                                <div className="textarea-block" data-required={actionData.at6["contract-other-description"].required ? true : false}>
                                                    <textarea onChange={(e) => setInput(prev => ({ ...prev, contractOtherDescription: e.target.value }))}
                                                        name="ad_contract-other-description" placeholder="">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Только поле для описания
                                        (input.actionType === "Проверка договора по форме заказчика" || input.actionTypeId === "at7" ||
                                            input.actionType === "Проверка иных документов по форме заказчика (НДА, соглашения и т.д.)" || input.actionTypeId === "at8"
                                        )
                                        &&
                                        <>
                                            <div className="content-form-item content-form-item--full-width">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">Описание документа:</label>
                                                </div>

                                                <div className="textarea-block">
                                                    <textarea onChange={(e) => setInput(prev => ({ ...prev, contractVerificationDescription: e.target.value }))}
                                                        name="ad_contract-verification-description" placeholder="">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Партнерский договор
                                        (input.actionType === "Партнерский договор" || input.actionTypeId === "at9")
                                        &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{actionDataAt9["partners-contract-period"].title} {actionDataAt9["partners-contract-period"].required && <i>*</i>}</label>
                                                    <p>{actionDataAt9["partners-contract-period"].hint}</p>
                                                </div>

                                                <div className="date-block" data-required={actionDataAt9["partners-contract-period"].required ? true : false}>
                                                    <Datepicker name="ad_partners-contract-period" chgDate={chgDate} inputName="partnersContractPeriod" />
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   // Согласие на анонс
                                        (input.actionType === "Согласие на анонс" || input.actionTypeId === "at10")
                                        &&
                                        <>
                                            {/* Есть ли приложение к договору? */}
                                            <div className="content-form-item content-form-item--full-width">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{actionDataAt10["consent-announcement-appendix"].title} {actionDataAt10["consent-announcement-appendix"].required && <i>*</i>}</label>
                                                </div>

                                                <div className="content-form-item-right" data-required={actionDataAt10["consent-announcement-appendix"].required ? true : false}>
                                                    <div className="label-radio-btns-wrap">
                                                        {showLabelRadioBtn(actionDataAt10["consent-announcement-appendix"], "ad_consent-announcement-appendix", "consentAnnouncementAppendix")}
                                                    </div>
                                                    {
                                                        input.consentAnnouncementAppendix === "Да" &&
                                                        <div className="whats-do-adds">
                                                            <input type="text" name="ad_consent-announcement-appendix-number" placeholder="Номер приложения к договору"
                                                                onChange={(e) => setInput(prev => ({ ...prev, consentAnnouncementAppendixNumber: e.target.value }))} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <hr />

                                            {   //Каким именно способом предполагается анонс работ?
                                                input.consentAnnouncementAppendix != null && input.consentAnnouncementAppendix != "" &&
                                                <>
                                                    <div className="content-form-item content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt10["consent-announcement-appendix-way"].title} {actionDataAt10["consent-announcement-appendix-way"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="content-form-item-right" data-required={actionDataAt10["consent-announcement-appendix-way"].required ? true : false}>
                                                            <div className="label-radio-box">
                                                                {showLabelRadio(actionDataAt10["consent-announcement-appendix-way"], "ad_consent-announcement-appendix-way", "consentAnnouncementAppendixWay")}
                                                            </div>
                                                            {
                                                                input.consentAnnouncementAppendixWay === "Нестандартная форма анонса" &&
                                                                <div className="whats-do-adds">
                                                                    <div className="small-title">{actionDataAt10["consent-announcement-appendix-way-features"].title}</div>
                                                                    <p dangerouslySetInnerHTML={{ __html: actionDataAt10["consent-announcement-appendix-way-features"].hint }}></p>
                                                                    <textarea onChange={(e) => setInput(prev => ({ ...prev, consentAnnouncementAppendixWayFeatures: e.target.value }))}
                                                                        name="ad_consent-announcement-appendix-way-features" placeholder="Особенности анонса работ">
                                                                    </textarea>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Дополнительные договоренности / комментарии
                                                input.consentAnnouncementAppendixWay != null && input.consentAnnouncementAppendixWay != "" &&
                                                <>
                                                    <div className="content-form-item content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt10["consent-announcement-agreements"].title} {actionDataAt10["consent-announcement-agreements"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="textarea-block">
                                                            <textarea name="ad_consent-announcement-agreements" placeholder="Введите сообщение…"
                                                                onChange={(e) => setInput(prev => ({ ...prev, consentAnnouncementAgreements: e.target.value }))} >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }
                                        </>
                                    }

                                    {   //Счет-оферта для клиента
                                        (input.actionType === "Счет-оферта для клиента" || input.actionTypeId === "at11")
                                        && ((input.contactPerson !== '' && input.contactPerson != null) ||
                                            (input.contactPersonPosition !== '' && input.contactPersonPosition != null) ||
                                            (input.contactPersonPhone !== '' && input.contactPersonPhone != null) ||
                                            (input.contactPersonEmail !== '' && input.contactPersonEmail != null))
                                        &&
                                        <>
                                            {/* //Какой отдел будет выполнять/заказывать работу по приложению? */}
                                            <>
                                                <div className="content-form-item">
                                                    <div className="content-form-item-desc">
                                                        <label htmlFor="">{actionDataAt11['department'].title} {actionDataAt11['department'].required && <i>*</i>}</label>
                                                    </div>

                                                    <div className="label-radio-btns-wrap" data-required={actionDataAt11['department'].required ? true : false}>
                                                        {showLabelRadioBtn(actionDataAt11['department'], "ad_department", "department")}
                                                    </div>
                                                </div>
                                                <hr />
                                            </>
                                            {/*
                                            {
                                                input.department != null && input.department != ""
                                                &&
                                                <>
                                                    <div className="content-form-item">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt11["invoice-offer-client-subject"].title} {actionDataAt11["invoice-offer-client-subject"].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="list-radio-btns" data-required={actionDataAt11["invoice-offer-client-subject"].required ? true : false}>
                                                            {
                                                                Object.entries(actionDataAt11['invoice-offer-client-subject'].options).map(([ind, option]) => {
                                                                    return <LabelRadio key={option.id}
                                                                        changeLabelRadio={() => (changeLabelRadio("invoiceOfferClientSubject", option.title, option.id))}
                                                                        name="ad_invoice-offer-client-subject" value={option.id} title={option.title}
                                                                    />
                                                                })
                                                            }

                                                            {
                                                                (input.invoiceOfferClientSubject === "Другое" || input.invoiceOfferClientSubjectId === "iocs17") &&
                                                                <div className="other-input">
                                                                    <input onChange={() => setInput(prev => ({ ...prev, invoiceOfferClientSubjectOther: "Другое" }))}
                                                                        name="ad_invoice-offer-client-subject-other" type="text" placeholder="Другое" />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            } */}

                                            {   //Предмет приложения
                                                input.department != null && input.department != "" &&
                                                <>
                                                    <div className="content-form-item">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{data["action-data"].at11[`invoice-offer-client-subject-${input.departmentId}`].title} {data["action-data"].at11[`invoice-offer-client-subject-${input.departmentId}`].required && <i>*</i>}</label>
                                                        </div>

                                                        <div className="whats-do-wrap" data-required={data["action-data"].at11[`invoice-offer-client-subject-${input.departmentId}`].required ? true : false}>
                                                            <div className="whats-do-list">
                                                                {
                                                                    showApplicationsGroups(data["action-data"].at11, applicationsInGroups(invoiseApplicationsDepartment[input.departmentId]), input.departmentId, 'invoice-offer-client-subject')
                                                                }

                                                                {
                                                                    (input.applicationSubject === "Другое" ||
                                                                        (input.departmentId === "development" && input.applicationSubjectId === "asd35") ||
                                                                        (input.departmentId === "digital" && input.applicationSubjectId === "asd11"))
                                                                    &&
                                                                    <div className="other-input">
                                                                        <input onChange={() => setInput(prev => ({ ...prev, applicationSubjectOther: "Другое" }))}
                                                                            name={`apps-1_invoice-offer-client-subject-${input.departmentId}-other`} type="text" placeholder="Другое" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Есть ли информационные материалы?
                                                input.applicationSubject != null && input.applicationSubject != "" &&
                                                <>
                                                    <div className="content-form-item content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt11["invoice-offer-client-materials"].title} {actionDataAt11["invoice-offer-client-materials"].required && <i>*</i>}</label>
                                                            <p>В документах с подрядчиками информационные материалы можно не указывать</p>
                                                        </div>

                                                        <div className="whats-do-wrap" data-required={actionDataAt11['invoice-offer-client-materials'].required ? true : false}>
                                                            <div className="labels">
                                                                {showLabelRadio(actionDataAt11["invoice-offer-client-materials"], "ad_invoice-offer-client-materials", "invoiceOfferClientMaterials")}
                                                            </div>

                                                            {
                                                                (input.invoiceOfferClientMaterials === "Да" || input.invoiceOfferClientMaterialsId === "iocm1") &&
                                                                <div className="adds" data-required={true}>
                                                                    <b>{actionDataAt11["invoice-offer-client-materials-list"].title}</b>
                                                                    <span>Выберите один или несколько вариантов</span>
                                                                    <div className="checkboxs">
                                                                        {showLabelCheckbox(actionDataAt11["invoice-offer-client-materials-list"], "ad_invoice-offer-client-materials-list[]", "invoiceOfferClientMaterialsList")}
                                                                    </div>

                                                                    {   //Наименования, формат и объем/количество материалов
                                                                        input.invoiceOfferClientMaterialsList && input.invoiceOfferClientMaterialsList.includes("iocml11") &&
                                                                        <div className="textarea-block">
                                                                            <b>{actionDataAt11["invoice-offer-client-materials-other"].title}</b>
                                                                            <textarea onChange={(e) => setInput(prev => ({ ...prev, invoiceOfferClientMaterialsOther: e.target.value }))}
                                                                                name="ad_invoice-offer-client-materials-other" placeholder="Наименования, формат и объем/количество материалов">
                                                                            </textarea>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Работы / услуги
                                                input.invoiceOfferClientMaterials != null && input.invoiceOfferClientMaterials != "" &&
                                                <>
                                                    <div className="content-form-item">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">Работы / услуги <i>*</i></label>
                                                        </div>

                                                        <div className="content-form-item-right" data-required="true">
                                                            <div className="item-right-label">
                                                                <div className="small-title">{actionDataAt11["invoice-offer-client-work-desc"].title}</div>
                                                                <input type="text" name="ad_invoice-offer-client-work-desc" placeholder="Введите описание"
                                                                    onChange={(e) => setInput(prev => ({ ...prev, invoiceOfferClientWorkDesc: e.target.value }))} />
                                                            </div>

                                                            <div className="item-right-label">
                                                                <div className="small-title">{actionDataAt11["invoice-offer-client-work-price"].title}</div>
                                                                <input type="text" name="ad_invoice-offer-client-work-price" placeholder="Введите описание"
                                                                    onChange={(e) => setInput(prev => ({ ...prev, invoiceOfferClientWorkPrice: e.target.value }))} />
                                                            </div>

                                                            <div className="item-right-label">
                                                                <div className="date-block">
                                                                    <img src={calendar} alt="" />
                                                                    <div className="small-title">{actionDataAt11["invoice-offer-client-work-date"].title}</div>
                                                                    <div className="date-inputs">
                                                                        <Datepicker name="ad_invoice-offer-client-work-date" chgDate={chgDate} inputName="invoiceOfferClientWorkDate" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="item-right-label">
                                                                <div className="small-title">{actionDataAt11["invoice-offer-client-work-format"].title}</div>
                                                                <div className="checkboxs">
                                                                    {showLabelCheckbox(actionDataAt11["invoice-offer-client-work-format"], "ad_invoice-offer-client-work-format[]", "invoiceOfferClientWorkFormat")}
                                                                </div>

                                                                {   //Наименования, формат и объем/количество материалов
                                                                    input.invoiceOfferClientWorkFormat && input.invoiceOfferClientWorkFormat.includes("f6") &&
                                                                    <input onChange={() => setInput(prev => ({ ...prev, invoiceOfferClientWorkFormatOther: "Другое" }))}
                                                                        name="ad_invoice-offer-client-work-format-other" type="text" placeholder="" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr />
                                                </>
                                            }

                                            {   //Вложения
                                                (input.invoiceOfferClientWorkDesc && input.invoiceOfferClientWorkPrice &&
                                                    input.invoiceOfferClientWorkFormat && input.invoiceOfferClientWorkDate
                                                )
                                                &&
                                                <>
                                                    <div className="content-form-item content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">Реквизиты компании клиента <i>*</i></label>
                                                            <p dangerouslySetInnerHTML={{ __html: 'Введите реквизиты или приложите карточку организации' }}></p>
                                                        </div>

                                                        <div className="textarea-block" data-required='true'>
                                                            <textarea name="ad_links" placeholder="Реквизиты / Ссылка на документы"></textarea>
                                                            <InputFile name="ad_files" multiple={true} />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }
                                        </>
                                    }

                                    {   //Счет–оферта для партнера
                                        (input.actionType === "Счет–оферта для партнера" || input.actionTypeId === "at12")
                                        &&
                                        <>
                                            <div className="content-form-item">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">Работы / услуги <i>*</i></label>
                                                </div>

                                                <div className="content-form-item-right" data-required="true">
                                                    <div className="item-right-label">
                                                        <div className="small-title">{actionDataAt12["invoice-offer-partner-work-period-from"].title}</div>
                                                        <div className="date-block">
                                                            <Datepicker name="ad_invoice-offer-partner-work-period-from" inputName="invoiceOfferPartnerWorkPeriodFrom"
                                                                chgDate={chgDate} maxDate={input.invoiceOfferPartnerWorkPeriodTo} />
                                                        </div>
                                                    </div>

                                                    <div className="item-right-label">
                                                        <div className="small-title">{actionDataAt12["invoice-offer-partner-work-period-to"].title}</div>
                                                        <div className="date-block">
                                                            <Datepicker name="ad_invoice-offer-partner-work-period-to" inputName="invoiceOfferPartnerWorkPeriodTo"
                                                                chgDate={chgDate} minDate={input.invoiceOfferPartnerWorkPeriodFrom} />
                                                        </div>
                                                    </div>

                                                    <div className="item-right-label">
                                                        <div className="small-title">{actionDataAt12["invoice-offer-partner-work-price"].title}</div>
                                                        <input type="text" name="ad_invoice-offer-partner-work-price" placeholder=""
                                                            onChange={(e) => setInput(prev => ({ ...prev, invoiceOfferPartnerWorkPrice: e.target.value }))} />
                                                    </div>

                                                    <div className="item-right-label">
                                                        <div className="small-title">{actionDataAt12["invoice-offer-partner-work-due-days"].title}</div>
                                                        <input type="text" name="ad_invoice-offer-partner-work-due-days" placeholder="Введите число рабочих дней"
                                                            onChange={(e) => setInput(prev => ({ ...prev, invoiceOfferPartnerWorkDueDays: e.target.value }))} />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {   //Описание для типа Другое
                                        (input.actionType === "Другое" || input.actionTypeId === "at13")
                                        &&
                                        <>
                                            <div className="content-form-item content-form-item--full-width">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{actionData.at13["contract-other-description"].title}</label>
                                                </div>

                                                <div className="textarea-block">
                                                    <textarea onChange={(e) => setInput(prev => ({ ...prev, contractOtherDescription: e.target.value }))}
                                                        name="ad_contract-other-description" placeholder="">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    }

                                    {
                                        ((input.actionTypeId === "at1" && input[`paymentScheme${appsArray.length}`] != null && input[`paymentScheme${appsArray.length}`]) ||
                                            (input.actionTypeId === "at2" && input[`paymentScheme${appsArray.length}`] != null && input[`paymentScheme${appsArray.length}`]) ||
                                            (input.actionTypeId === "at3" && input.additionalAgreementSubject) ||
                                            input.actionTypeId === "at4" || input.actionType === "NDA" ||
                                            (input.actionTypeId === "at5" && input.authorEmail) ||
                                            (input.actionTypeId === "at6" && input.contractOtherDescription) ||
                                            (input.actionTypeId === "at7" && input.contractVerificationDescription) ||
                                            (input.actionTypeId === "at8" && input.contractVerificationDescription) ||
                                            (input.actionTypeId === "at9" && input.partnersContractPeriod) ||
                                            (input.actionTypeId === "at10" && input.consentAnnouncementAppendixWay) ||
                                            (input.actionTypeId === "at11" && input.invoiceOfferClientWorkDesc && input.invoiceOfferClientWorkPrice &&
                                                input.invoiceOfferClientWorkFormat && input.invoiceOfferClientWorkDate) ||
                                            (input.actionTypeId === "at12" && input.invoiceOfferPartnerWorkPeriodFrom && input.invoiceOfferPartnerWorkPrice &&
                                                input.invoiceOfferPartnerWorkPeriodTo && input.invoiceOfferPartnerWorkDueDays) ||
                                            (input.actionTypeId === "at13" && input.contractOtherDescription)
                                        )
                                        &&
                                        <>
                                            {   //Дополнительные договоренности / комментарии
                                                (input.actionTypeId === "at11" || input.actionTypeId === "at12"
                                                )
                                                &&
                                                <>
                                                    <div className="content-form-item content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            <label htmlFor="">{actionDataAt11['invoice-offer-comments'].title}</label>
                                                        </div>

                                                        <div className="textarea-block">
                                                            <textarea name="ad_invoice-offer-comments" placeholder="Введите сообщение…"></textarea>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            {   //Вложения
                                                (input.actionTypeId != "at10" && input.actionTypeId != "at11" &&
                                                    input.actionTypeId != "at12"
                                                )
                                                &&
                                                <>
                                                    <div className="content-form-item content-form-item--full-width">
                                                        <div className="content-form-item-desc">
                                                            {
                                                                (data["action-data"][input.actionTypeId]["files"] && data["action-data"][input.actionTypeId]["files"].title &&
                                                                    <label htmlFor="">{data["action-data"][input.actionTypeId]["files"].title} {data["action-data"][input.actionTypeId]["files"].required && <i>*</i>}</label>
                                                                ) ||
                                                                <label htmlFor="">Вложения</label>
                                                            }
                                                            {
                                                                data["action-data"][input.actionTypeId]["files"] && data["action-data"][input.actionTypeId]["files"].hint &&
                                                                <div dangerouslySetInnerHTML={{ __html: data["action-data"][input.actionTypeId]["files"].hint }}></div>
                                                            }
                                                        </div>

                                                        <div className="textarea-block">
                                                            <textarea name="ad_links" placeholder="Реквизиты / Ссылка на документы"></textarea>
                                                            <InputFile name="ad_files" multiple={true} />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            }

                                            <div className="content-form-item content-form-item-row content-form-item--full-width">
                                                <div className="content-form-item-desc">
                                                    <label htmlFor="">{subscribers.title} {subscribers.required && <i>*</i>}</label>
                                                    <p>{subscribers.hint}</p>
                                                </div>

                                                <div className="subscribes">
                                                    <div className="small-title">{subscribers.subtitle}</div>
                                                    <div className="content-select content-select-big">
                                                        <ReactSelect isMulti defaultValue='' options={subscribersList} className="react-select-container"
                                                            classNamePrefix="react-select" name="ad_subscribers[]" placeholder="Введите имя" />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="submit-block">
                                                <button type="submit" className="btn-white btn-white-big">Отправить</button>
                                            </div>
                                        </>
                                    }
                                </form>
                            }
                        </div>

                        {isLoading &&
                            <div className="layout-fixed">
                                <h2>Идёт запрос . . .</h2>
                                <img className="loading" src={spinner} alt="Spinner" />
                            </div>
                        }
                    </div>
                </>
            )}
        </div>
    )
}

export default CreateDocument
