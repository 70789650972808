import { React, useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import Header from '../../components/Header/Header';
import styles from './SupportReports.scss';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import spinner from '../../assets/img/spinner.svg';
import Datepicker, { convertDate } from "../../components/Datepicker/Datepicker";
import fileDownload from 'js-file-download';
import { useNavigate } from 'react-router-dom';

const monthSelect = [
    { value: "01", label: "Январь" },
    { value: "02", label: "Февраль" },
    { value: "03", label: "Март" },
    { value: "04", label: "Апрель" },
    { value: "05", label: "Май" },
    { value: "06", label: "Июнь" },
    { value: "07", label: "Июль" },
    { value: "08", label: "Август" },
    { value: "09", label: "Сентябрь" },
    { value: "10", label: "Октябрь" },
    { value: "11", label: "Ноябрь" },
    { value: "12", label: "Декабрь" }
]

const SupportReports = ({ changeTheme, themeBlack }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const navigate = useNavigate();

    let yearSelect = [];
    let startYear = 2017;
    let currentYear = new Date().getFullYear();

    function arrYears(currYear) {
        const start = startYear;
        const end = currYear;
        for (let i = start; i <= end; ++i) {
            yearSelect.push({ value: i, label: i });
        }
    }

    arrYears(currentYear);

    const [errNullProject, setErrNullProject] = useState(false);
    const [isLoadingStart, setIsLoadingStart] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [listProjects, setListProjects] = useState([]);
    const [listClientProject, setListClientProject] = useState([]);
    const [typesReport, setTypesReport] = useState([]);
    const [typeReportValue, setTypeReportValue] = useState(1);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [errProjectMessage, SetErrProjectMessage] = useState(false);
    const [errFormatMessage, SetErrFormatMessage] = useState(false);
    const [errDateMessage, SetErrDateMessage] = useState(false);
    const [errTypesMessage, SetErrTypesMessage] = useState(false);

    const [errProject, SetErrProject] = useState(true);
    const [errFormat, SetErrFormat] = useState(false);
    const [errDate, SetErrDate] = useState(true);
    const [errTypes, SetErrTypes] = useState(false);

    const [postDataObj, setPostDataObj] = useState({
        'projects_ids[]': null,
        type_id: 1,
        format: 'xlsx'
    });

    const getData = async (state, url) => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            });

            if (data) {
                setIsLoadingStart(false);
                if (state === setListProjects) {
                    if (data.data.length === 0) {
                        setErrNullProject(true);
                    }
                }
                console.log('Data ', data);
                state(data.data);
            }

        } catch (error) {
            console.log('error');
        }
    };

    useEffect(() => {
        if (token) {
            getData(setListProjects, `${process.env.REACT_APP_BACKEND_URL}/v1/collab/projects?filters[current_user_projects]`);
            getData(setListClientProject, `${process.env.REACT_APP_BACKEND_URL}/v1/collab/projects?filters[current_user_projects]&filters[client_projects]`);
            getData(setTypesReport, `${process.env.REACT_APP_BACKEND_URL}/v1/types/reports`);
        } else {
            navigate('/auth');
        }
    }, [])

    const submitFormReport = async (e) => {
        e.preventDefault();

        let form = e.target;

        // console.log('Проект - ', errProject, 'Дата - ', errDate, 'Тип - ', errTypes, 'Формат - ', errFormat);

        if (postDataObj['projects_ids[]'] === null || postDataObj['projects_ids[]'] === '') {
            SetErrProject(true);
            SetErrProjectMessage(true);
        }

        if (typeReportValue !== 1) {
            if ((!postDataObj.start_date || postDataObj.start_date === null || postDataObj.start_date === '') || (!postDataObj.end_date || postDataObj.end_date === null || postDataObj.end_date === '')) {
                SetErrDate(true);
                SetErrDateMessage(true);
            } else {
                SetErrDate(false);
                SetErrDateMessage(false);
            }
        } else {
            if ((!postDataObj.month || postDataObj.month === null || postDataObj.month === '') || (!postDataObj.year || postDataObj.year === null || postDataObj.year === '')) {
                SetErrDate(true);
                SetErrDateMessage(true);
            } else {
                SetErrDate(false);
                SetErrDateMessage(false);
            }
        }

        if (postDataObj.type_id === null || postDataObj.type_id === '') {
            SetErrTypes(true);
            SetErrTypesMessage(true);
        }

        if (postDataObj.format === null || postDataObj.format === '') {
            SetErrFormat(true);
            SetErrFormatMessage(true);
        }

        if ((errProject || errDate || errFormat || errTypes) && (Object.values(postDataObj).every(x => x !== null || x !== ''))) {
            console.log('Нужно заполнить все необходимые поля');
            form.querySelectorAll('.error-text')[0].scrollIntoView({ block: "center", behavior: "smooth" });
        } else {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_BACKEND_URL}/v1/reports`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'multipart/form-data',
                    'responseType': 'blob',
                    'Authorization': 'Bearer ' + token,
                },
                responseType: 'blob',
                data: postDataObj
            })
                .then((res) => {
                    console.log(res);
                    if (postDataObj.format !== 'csv') {
                        let url = window.URL.createObjectURL(res.data);
                        let a = document.createElement("a");
                        a.href = url;
                        a.setAttribute("download", "report");
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    } else {
                        fileDownload(res.data, 'report.csv');
                    }

                    setIsLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                    setIsLoading(false);
                })
        }
    };

    const changeTypes = (e, item) => {
        setTypeReportValue(+e.target.value);
        SetErrDate(true);
        if (e.target.value !== '' || e.target.value !== null) {
            SetErrTypes(false);
        }

        setPostDataObj({
            ...postDataObj,
            [e.target.name]: +e.target.value
        })

        if (e.target.value === '5') {
            let copiedpostDataObj = { ...postDataObj };
            delete copiedpostDataObj.month;
            delete copiedpostDataObj.year;
            setPostDataObj({ ...copiedpostDataObj, type_id: 5, start_date: null, end_date: null });
        } else {
            let copiedpostDataObj = { ...postDataObj };
            delete copiedpostDataObj.start_date;
            delete copiedpostDataObj.end_date;
            setPostDataObj({ ...copiedpostDataObj, type_id: 1, month: null, year: null });
        }
    }

    const changeProject = (e) => {
        if (e.target.value !== '' || e.target.value !== null) {
            SetErrProject(false);
            SetErrProjectMessage(false);
        }
        setPostDataObj({
            ...postDataObj,
            [e.target.name + '[]']: +e.target.value
        })
    }

    const changeData = (e, nameInput) => {
        if ((e.value !== '' || e.value !== null) && (nameInput === 'year')) {
            SetErrDate(false);
            SetErrDateMessage(false);
        }
        setPostDataObj({
            ...postDataObj,
            [nameInput]: +e.value
        })
    }

    const changeFormat = (e) => {
        if (e.target.value !== '' || e.target.value !== null) {
            SetErrFormat(false);
            SetErrFormatMessage(false);
        }
        setPostDataObj({
            ...postDataObj,
            [e.target.name]: e.target.value
        })
    }

    function chgDate(date, name, inputName) {
        if ((date === '' || date === null)) {

            if (inputName === 'start_date') {
                setStartDate(null);
                setEndDate(null);

                if (postDataObj.end_date !== '' || postDataObj.end_date !== null) {
                    SetErrDate(true);
                    SetErrDateMessage(true);
                }

            }

            if (inputName === 'end_date') {
                setEndDate(null);
                SetErrDate(true);
                SetErrDateMessage(true);
            }

            setPostDataObj({
                ...postDataObj,
                [inputName]: null
            })

        } else if ((date !== '' || date !== null)) {
            if (inputName === 'start_date') {
                setStartDate(date);
                SetErrDateMessage(false);
            }

            if (inputName === 'end_date') {
                setEndDate(date);
                SetErrDate(false);
                SetErrDateMessage(false);
            }

            setPostDataObj({
                ...postDataObj,
                [inputName]: convertDate(date)
            })
        }
    }


    // console.log(startDate);
    // console.log('Проект - ', errProject, 'Дата - ', errDate, 'Тип - ', errTypes, 'Формат - ', errFormat);
    // console.log('postDataObj', postDataObj);

    return (
        <div>
            <Helmet>
                <title>Отчеты поддержки</title>
            </Helmet>
            <div className="content content-support">
                {isLoadingStart &&
                    <div className="layout-fixed">
                        <h2>Загружаем проекты</h2>
                        <img className="loading" src={spinner} alt="Spinner" />
                    </div>
                }
                {isLoading &&
                    <div className="layout-fixed">
                        <h2>Обработка запроса</h2>
                        <img className="loading" src={spinner} alt="Spinner" />
                    </div>
                }
                <div className="container">
                    <h1>Отчеты поддержки</h1>

                    {errNullProject &&
                        <h2>Не найдено проектов</h2>
                    }
                    <form autoComplete="off" onSubmit={submitFormReport} className={`support-report-form ${errNullProject ? 'hide' : ''}`}>
                        <div className="support-wrap">
                            <div className="support-box__item">
                                <div className="users-wrap">
                                    <div className="title">Внутренний проект: <span className='error-text'>{errProjectMessage ? 'Выберите проект' : ''}</span></div>
                                    <div className="users-box list-scroll-wrap">
                                        <div className="list-scroll-items">
                                            {
                                                listProjects.map((element, index) => {
                                                    return <label key={element.name} className="label-radio-btns">
                                                        <input type="radio" onChange={(e) => changeProject(e)} name="projects_ids" value={element.id} />
                                                        <span>{element.name}</span>
                                                    </label>;
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sub-title">Тип отчета: <span className='error-text'>{errTypesMessage ? 'Выберите тип отчёта' : ''}</span></div>

                        <div className="filter-list">
                            {
                                typesReport.map((element, index) => {
                                    if (element.name === 'Паспорт проекта' || element.name === 'По задачам с произвольной датой') {
                                        return <label key={element.name} className="label-radio-btns">
                                            <input defaultChecked={element.name === 'Паспорт проекта' ? true : false} onChange={(e) => changeTypes(e)} type="radio" name="type_id" value={element.id} />
                                            <span>{element.name}</span>
                                        </label>;
                                    }

                                })
                            }
                        </div>

                        <div className="support-date">
                            <div className="sub-title">Дата: <span className='error-text'>{errDateMessage ? 'Введите дату' : ''}</span></div>

                            {
                                typeReportValue === 1 &&
                                <div className="support-selects">
                                    <div className="support-selects__item">
                                        <div className="content-select">
                                            <ReactSelect name="month" required options={monthSelect} onChange={(e) => changeData(e, 'month')} className="react-select-container" classNamePrefix="react-select" placeholder="Выберите месяц" />
                                        </div>
                                    </div>

                                    <div className={`support-selects__item ${!postDataObj.month ? 'hide' : ''}`}>
                                        <div className="content-select">
                                            <ReactSelect name="year" required options={yearSelect} onChange={(e) => changeData(e, 'year')} className="react-select-container" classNamePrefix="react-select" placeholder="Выберите год" />
                                        </div>
                                    </div>
                                </div>
                            }


                            {
                                typeReportValue !== 1 &&
                                <div className="custom-dates">
                                    <div className="date-item date-from">
                                        <span>С:</span>
                                        <Datepicker autoComplete="off" selected={startDate} startDate={startDate} selectsStart chgDate={chgDate} maxDate={new Date()} name="start_date" inputName="start_date" />
                                    </div>

                                    <div className={`date-item date-to ${!postDataObj.start_date ? 'hide' : ''}`}>
                                        <span>По:</span>
                                        <Datepicker autoComplete="off" selected={endDate} chgDate={chgDate} selectsEnd startDate={startDate} minDate={startDate} maxDate={new Date()} name="end_date" inputName="end_date" />
                                    </div>
                                </div>
                            }

                            <div className="sub-title">Формат отчета: <span className='error-text'>{errFormatMessage ? 'Выберите формат' : ''}</span></div>

                            <div className="format-list">
                                <label className="label-radio-btns">
                                    <input type="radio" defaultChecked={true} onChange={(e) => changeFormat(e)} name="format" value="xlsx" />
                                    <span>xlsx</span>
                                </label>
                                <label className="label-radio-btns">
                                    <input type="radio" onChange={(e) => changeFormat(e)} name="format" value="csv" />
                                    <span>csv</span>
                                </label>
                            </div>

                            <button type="submit" className="btn-white">Создать отчет</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default SupportReports