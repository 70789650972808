import React, { useEffect, useState } from 'react'
import styles from './MainBanner.scss';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';

const Main = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  const [infoBanner, setInfoBanner] = useState([]);
  // const [arrActiveBanners, setActiveBanners] = useState([]);
  const [isShow, setIsShow] = useState(true);

  const arrActiveBanners = [];

  const hiddenBanner = (e) => {
    e.preventDefault();
    setIsShow(!isShow);
  }

  useEffect(() => {

    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_BACKEND_URL}/v1/admin/banners`,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        });

        if (data) {
          console.log(data);
          setInfoBanner(data.data);
        }

      } catch (error) {
        console.log('error')
      }
    }

    if (token) {
      fetchData()
    } else {
      navigate('/auth');
    }

  }, [])

  infoBanner.map((item, index) => (
    arrActiveBanners.push(item.active)
  ))


  return (
    <>
      {arrActiveBanners.includes(1) &&
        <div className={`${"main-banner-wrap"} ${!isShow ? "_hidden" : ''}`}>
          <div className="container">
            <a href="#" onClick={hiddenBanner} className="close-banner">Закрыть <i></i></a>
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
            >
              {
                infoBanner.map((item, index) => (
                  item.active ?
                    <SwiperSlide>
                      <div className="main-banner">
                        <div className="main-banner-title">
                          <h1>{item.title}</h1>

                          <div className="main-banner-desc">
                            <p>{item.description} {item.link ? <a href={item.link}>{item.link_text ? item.link_text : 'читайте тут'}</a> : ''}</p>
                          </div>
                        </div>

                        <div className="main-banner-img">
                          <img src={item.image} alt="" />
                          <img src={item.image_black} alt="" />
                        </div>
                      </div>
                    </SwiperSlide>
                    : ''
                ))
              }
            </Swiper>
          </div>
        </div>
      }
    </>
  )
}

export default Main