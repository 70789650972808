import React, { useState } from 'react'
import closeGreen from '../../assets/img/close-green.svg'
import clear from '../../assets/img/clear.svg'
import ReactSelect from 'react-select'
import DatalistInput from 'react-datalist-input'

const StageSmall = ({num, name, projectNum, remove, index, chgSteps, stagesData}) => {

  function clearInputs(e) {
      const inputs = e.target.closest(".step").querySelectorAll("input");
      inputs.forEach((el) => el.value = '');
  }

  return (
    <div>
        <div className="step" id={name}>
            {num === 1 && <div className="clear-inputs" onClick={clearInputs}>Очистить поля <i><img src={clear} alt="" /></i></div>}
            {num != 1 && <div onClick={() => remove(name-1)} id={name} className="delete-step">Удалить этап <i><img src={closeGreen} alt="" /></i></div>}

            <b>Этап №{num}</b>
            <div className="step-content">
                <div className="step-content-top">
                  <div className="step-content-top-item content-select">
                    {/* <input type="text" onChange={chgSteps} name={'projects-' + projectNum + '_stage-' + num + '_name'} placeholder="Название" /> */}
                    {/* <DatalistInput type="text" onChange={chgSteps} className="datalist-input" items={items} name={'name'+nameInput} placeholder="Название" /> */}

                    <ReactSelect options={stagesData} className="react-select-container" onChange={chgSteps}
                      classNamePrefix="react-select" name={'projects-' + projectNum + '_stage-' + num + '_name'} placeholder='Название' />
                  </div>
                  <div className="step-content-top-item num">
                    <input type="number" min="0" onChange={chgSteps} name={'projects-' + projectNum + '_stage-' + num + '_price'} placeholder="Стоимость" />
                    </div>
                  <div className="step-content-top-item">
                    <input type="text" onChange={chgSteps} name={'projects-' + projectNum + '_stage-' + num + '_time'} placeholder="Срок реализации (дней)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StageSmall