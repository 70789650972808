import { React, useState, useEffect } from 'react';
import useStickyState from '../../assets/js/useStickyState';
import Header from '../../components/Header/Header';
import styles from './NominateProduct.scss';
import { Helmet } from 'react-helmet';
import spinner from '../../assets/img/spinner.svg';
import axios from 'axios';
import ReactSelect from 'react-select';
import { el } from 'date-fns/locale';

const NominateProduct = ({ changeTheme, themeBlack }) => {

    const token = JSON.parse(localStorage.getItem("token"));
    const [listClients, setListClients] = useState([]);
    const [listProjects, setListProjects] = useState([]);
    const [listProductsStages, setListProductsStages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [nullProject, setNullProject] = useState(false);
    const [successTextStatus, setSuccessTextStatus] = useState(false);
    const [successText, setSuccessText] = useState(false);
    const [flagNullOption, setFlagNullOption] = useState();
    const [disableBtn, setDisableBtn] = useState(true);
    // const [currStagesList, setCurrStagesList] = useState([{value: 0, label: "Не задан"}]);
    let currData = [];
    let [arrActual, setArrActual] = useState([]);
    let [arrActualValues, setArrActualValues] = useState([]);
    const filters = {
        "clients_ids": [],
        "projects_ids": [],
        "type": ""
    };
    /*
        const filtersChange = (event) => {
            if (event.target.checked) {
                if (event.target.name === 'projects_ids') {
                    setFilters(filters.projects_ids.push((+event.target.value)));
                } else if(event.target.name === 'clients_ids') {
                    setFilters(filters.clients_ids.push((+event.target.value)));
                    console.log(filters.clients_ids);
                }
            } else {
                if(event.target.name === 'projects_ids') {
                    let idx = filters.projects_ids.indexOf(+event.target.value);
                    if(idx) {
                        filters.projects_ids.splice(idx, 1);
                    }
                } else if(event.target.name === 'clients_ids') {
                    console.log(+event.target.value)
                    let idx = filters.clients_ids.indexOf(+event.target.value);
                    if(idx) {
                        filters.clients_ids.splice(idx, 1);
                    }
                }
            }
    
            if (event.target.checked && event.target.name === 'projects_ids') {
                filters.projects_ids.push((+event.target.value));
            } else if(event.target.checked && event.target.name === 'clients_ids') {
                filters.clients_ids.push((+event.target.value));
                console.log(filters.clients_ids)
                // filters.clients_ids.push(+event.target.value);
            } else if(!event.target.checked && event.target.name === 'projects_ids') {
                let idx = filters.projects_ids.indexOf(+event.target.value).index;
                if(idx) {
                    filters.projects_ids.splice(idx, 1);
                }
            } else if(!event.target.checked && event.target.name === 'clients_ids') {
                console.log(filters.clients_ids)
                let idx = filters.clients_ids.indexOf(+event.target.value);
                if(idx) {
                    filters.clients_ids.splice(idx, 1);
                }
            } else {
                filters.type = event.target.value
            }
            console.log(filters)
        }
    */
    const scrollTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }, 5);
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const { data } = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/collab/clients?filters[product_and_stage]`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                });

                if (data) {
                    console.log('dataMyProjects ', data);
                    setListClients(data.data);
                    setListProjects(data.data);
                    setIsLoading(false);
                    setNullProject(false);

                    if (data.data.length === 0) {
                        setNullProject(true);
                    }
                }

            } catch (error) {
                setIsLoading(false);
                console.log('error')
            }
        }
        fetchData()
    }, [])

    const filterRequest = (e) => {
        e.preventDefault();
        setIsLoading(true);

        let checkedClients = document.querySelectorAll('input[name="clients_ids"]:checked');
        let checkedProjects = document.querySelectorAll('input[name="projects_ids"]:checked');
        let typeFilter = document.querySelector('input[name="type"]:checked');

        checkedClients.forEach(el => {
            filters.clients_ids.push(+el.value);
        })

        checkedProjects.forEach(el => {
            filters.projects_ids.push(+el.value);
        })

        filters.type = typeFilter ? typeFilter.value : 'all';

        console.log('filtersSended', filters);

        const fetchData = async () => {
            try {
                const { data } = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/finance/set-product-and-stage-data`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    params: {
                        "filters[clients_ids]": filters.clients_ids,
                        "filters[projects_ids]": filters.projects_ids,
                        "filters[type]": filters.type
                    }
                });

                if (data) {
                    setListProductsStages(data);
                    console.log('dataFilters - ', data);
                    setIsLoading(false);
                    setNullProject(false);
                    setSuccessTextStatus(false);

                    console.log('currData', currData);
                }

                if (data.length === 0) {
                    setNullProject(true);
                    setSuccessTextStatus(false);
                }

            } catch (error) {
                setIsLoading(false);
                console.log('error');
            }
        }
        fetchData()
    }

    const setActualObj = (arr) => {
        arr = [];
        let tr = document.querySelectorAll('.tr-request');
        tr.forEach(el => {
            const trInputs = el.querySelectorAll('input[name]');
            const trData = {};

            trInputs.forEach(inp => {
                if (inp.name === 'department') {
                    trData[inp.name] = inp.value;
                } else {
                    trData[inp.name] = +inp.value;
                }
            })
            arr.push(trData);
            setArrActual(arr);
            console.log('arrActual', arrActual);
        })
    }

    // const funcGetData = (arr) => {
    //     let tr = document.querySelectorAll('.tr-request');
    //     tr.forEach(el => {
    //         const trInputs = el.querySelectorAll('input[name]');
    //         const trData = {};
    //         const selectValueData = {};
    //         trInputs.forEach(inp => {
    //             if(inp.name === 'department') {
    //                 trData[inp.name] = inp.value;
    //             } else {
    //                 trData[inp.name] = +inp.value;
    //             }
    //         })

    //         console.log('trInputs', trInputs);

    //         trInputs.forEach(inp => {
    //             if(inp.name === 'product_id' || inp.name === 'stage_id') {
    //                 selectValueData[inp.name] = +inp.value;
    //             }
    //         })

    //         console.log('selectValueData', selectValueData);

    //         arr.push(trData);

    //         console.log('postData', arr);
    //         console.log('currData', currData);
    //         console.log('actualData', arrActual);
    //         console.log('arrActual.length', arrActual.length);

    //         arrActual.length === 0 ? 

    //         currData.map((itm, idx) => {
    //             arr.map((item, index) => {
    //                 if (itm.task_list_id === item.task_list_id && itm.project_id === item.project_id && itm.product_id === item.product_id && itm.stage_id === item.stage_id && itm.department === item.department) {
    //                     arr.splice(index, 1);
    //                 } 
    //             })
    //         }) : 
    //         arrActual.map((itm, idx) => {
    //             arr.map((item, index) => {
    //                 if (itm.task_list_id === item.task_list_id && itm.project_id === item.project_id && itm.product_id === item.product_id && itm.stage_id === item.stage_id && itm.department === item.department) {
    //                     arr.splice(index, 1);
    //                 } 
    //             })
    //         })
    //     })
    // }

    const saveProductsAndStages = (e) => {
        e.preventDefault();
        setIsLoading(true);

        let tr = document.querySelectorAll('.tr-request');
        let postData = [];

        tr.forEach(el => {
            const trInputs = el.querySelectorAll('input[name]');
            const trData = {};
            trInputs.forEach(inp => {
                if (inp.name === 'department') {
                    trData[inp.name] = inp.value;
                } else {
                    trData[inp.name] = +inp.value;
                }
            })

            postData.push(trData);

            if (arrActual.length === 0) {
                currData.map((itm, idx) => {
                    postData.map((item, index) => {
                        if (itm.task_list_id === item.task_list_id && itm.project_id === item.project_id && itm.product_id === item.product_id && itm.stage_id === item.stage_id && itm.department === item.department) {
                            postData.splice(index, 1);
                        }
                    })
                })
            } else {
                arrActual.map((itm, idx) => {
                    postData.map((item, index) => {
                        if (itm.task_list_id === item.task_list_id && itm.project_id === item.project_id && itm.product_id === item.product_id && itm.stage_id === item.stage_id && itm.department === item.department) {
                            postData.splice(index, 1);
                        }
                    })
                })
            }
        })

        const fetchData = async () => {
            try {
                const { data } = await axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_BACKEND_URL}/v1/finance/save-products-and-stages`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    data: postData
                });

                if (data) {
                    console.log('secusess');
                    setIsLoading(false);
                    setSuccessTextStatus(true);
                    setSuccessText(data.success);
                    setActualObj();
                    setDisableBtn(true);
                    scrollTop();

                    let arrValuesAfterSend = [];
                    let tr = document.querySelectorAll('.tr-request');
                    tr.forEach(el => {
                        const trInputs = el.querySelectorAll('input[name]');

                        trInputs.forEach(inp => {
                            if (inp.name === 'product_id' || inp.name === 'stage_id') {
                                arrValuesAfterSend.push(+inp.value);
                            }
                        })

                        setArrActualValues(arrValuesAfterSend);
                    })
                }

            } catch (error) {
                setIsLoading(false);
                console.log('error');
                if (error.response.status === 404) {
                    setSuccessTextStatus(true);
                    setSuccessText('Нет данных для изменения');
                    scrollTop();
                }
            }
        }
        fetchData()
    }

    // const changeSelect = (e) => {
    //     const tr = document.querySelectorAll('.tr-request');
    //     let postData = [];

    //     setTimeout(() => {
    //         tr.forEach(el => {
    //             const trInputs = el.querySelectorAll('input[name]');
    //             const trData = {};
    //             trInputs.forEach(inp => {
    //                 if(inp.name === 'department') {
    //                     trData[inp.name] = inp.value;
    //                 } else {
    //                     trData[inp.name] = +inp.value;
    //                 }
    //             })

    //             postData.push(trData);

    //             console.log('arrActual.length', arrActual.length);
    //             console.log('postData', postData);
    //             console.log('currData', currData);
    //             console.log('ArrActual', arrActual);
    //             // console.log('postData', postData);

    //             arrActual.length === 0 ? 

    //             currData.map((itm, idx) => {
    //                 postData.map((item, index) => {
    //                     if (itm.task_list_id === item.task_list_id && itm.project_id === item.project_id && itm.product_id === item.product_id && itm.stage_id === item.stage_id && itm.department === item.department) {
    //                         console.log('1', disableBtn);
    //                         setDisableBtn(true);
    //                         console.log('2', disableBtn);
    //                     } else {
    //                         console.log('111', disableBtn);
    //                         setDisableBtn(false);
    //                         console.log('222', disableBtn);
    //                     }
    //                 })
    //             }) 
    //             : 
    //             arrActual.map((itm, idx) => {
    //                 postData.map((item, index) => {
    //                     if (itm.task_list_id === item.task_list_id && itm.project_id === item.project_id && itm.product_id === item.product_id && itm.stage_id === item.stage_id && itm.department === item.department) {
    //                         setDisableBtn(true);
    //                     } else {
    //                         setDisableBtn(false);
    //                     }
    //                 })
    //             })
    //         })
    //     }, 150);
    // }

    const f = () => {
        let inputsSelectProduct = document.querySelectorAll('.react-select-container input[name="product_id"]');

    }

    const changeSelectProduct = (e, i) => {
        console.log(e);
        console.log(i);
        let valuesFromDOM = [];
        let inputsSelects = document.querySelectorAll('.react-select-container input[name]');

        let arrValues = [];


        setTimeout(() => {
            inputsSelects.forEach(el => {
                valuesFromDOM.push(+el.value);
                arrValues.push(+el.value === +el._wrapperState.initialValue);
            });

            if (arrActualValues.length === 0) {
                if (arrValues.includes(false)) {
                    setDisableBtn(false);
                } else {
                    setDisableBtn(true);
                }
            } else {
                let eqArr = (valuesFromDOM.length == arrActualValues.length) && valuesFromDOM.every(function (element, index) {
                    return element === arrActualValues[index];
                });
                console.log('valuesFromDOM', valuesFromDOM);
                console.log('arrActualValues', arrActualValues);
                console.log('eqArr', eqArr);

                if (eqArr) {
                    setDisableBtn(true);
                } else {
                    setDisableBtn(false);
                }
            }
        }, 100);
    }


    const changeSelect = (e) => {
        console.log(e);
        let valuesFromDOM = [];
        let inputsSelects = document.querySelectorAll('.react-select-container input[name]');
        let arrValues = [];

        setTimeout(() => {
            inputsSelects.forEach(el => {
                valuesFromDOM.push(+el.value);
                arrValues.push(+el.value === +el._wrapperState.initialValue);
            });

            if (arrActualValues.length === 0) {
                if (arrValues.includes(false)) {
                    setDisableBtn(false);
                } else {
                    setDisableBtn(true);
                }
            } else {
                let eqArr = (valuesFromDOM.length == arrActualValues.length) && valuesFromDOM.every(function (element, index) {
                    return element === arrActualValues[index];
                });
                console.log('valuesFromDOM', valuesFromDOM);
                console.log('arrActualValues', arrActualValues);
                console.log('eqArr', eqArr);

                if (eqArr) {
                    setDisableBtn(true);
                } else {
                    setDisableBtn(false);
                }
            }
        }, 100);
    }

    return (
        <div>
            <Helmet>
                <title>Назначить продукт и этап</title>
            </Helmet>
            <div className="content content-nominate-product">
                {isLoading &&
                    <div className="layout-fixed">
                        <h2>Идёт запрос . . .</h2>
                        <img className="loading" src={spinner} alt="Spinner" />
                    </div>
                }
                <div className="container">
                    <h1>Назначить продукт и этап</h1>
                    <form onSubmit={filterRequest} method="GET">
                        <div className="nominate-product-box">
                            <div className="nominate-product-box__item">
                                <div className="users-wrap">
                                    <div className="title">Клиент:</div>
                                    {nullProject && <p>У Вас нет клиентов</p>}
                                    <div className="users-box list-scroll-wrap">
                                        <div className="list-scroll-items clients">
                                            {
                                                listClients.map((element, index) => {
                                                    return <label key={element.name} className="label-radio-btns">
                                                        <input type="checkbox" name="clients_ids" value={element.id} />
                                                        <span>{element.name}</span>
                                                    </label>;
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="nominate-product-box__item">
                                <div className="users-wrap">
                                    <div className="title">Проект:</div>
                                    {nullProject && <p>У Вас нет проектов</p>}
                                    <div className="users-box list-scroll-wrap">
                                        <div className="list-scroll-items projects">
                                            {
                                                listProjects.map((element, index) => {
                                                    return element.projects.map((el, idx) => {
                                                        return <label key={el.name} className="label-radio-btns">
                                                            <input type="checkbox" name="projects_ids" value={el.id} />
                                                            <span>{el.name}</span>
                                                        </label>
                                                    })
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="nominate-product-filter">
                            <div className="sub-title">Фильтрация</div>
                            <div className="filter-list">
                                <label className="label-radio-btns">
                                    <input type="radio" name="type" defaultChecked value="all" />
                                    <span>Все списки</span>
                                </label>
                                <label className="label-radio-btns">
                                    <input type="radio" name="type" value="not_full" />
                                    <span>Нет продукта или этапа</span>
                                </label>
                                <label className="label-radio-btns">
                                    <input type="radio" name="type" value="full" />
                                    <span>Задана вся информация</span>
                                </label>
                            </div>
                            <button type="submit" className="btn-white">Отфильтровать</button>
                        </div>
                    </form>

                    {nullProject && <h2>Не найдено данных для отображения</h2>}
                    {successTextStatus && <h2>{successText}</h2>}

                    <form onSubmit={saveProductsAndStages} className="form-product-stages" method="POST">
                        <div className="nominate-product-table">
                            <table className="table-products-stages">
                                <tbody>
                                    <tr>
                                        <th>Клиент</th>
                                        <th>Проект</th>
                                        <th>Список задач</th>
                                        <th>Продукт</th>
                                        <th>Этап</th>
                                    </tr>
                                    {
                                        listProductsStages.map((element, index) => {
                                            let productsEl = element.products[Object.keys(element.products)[0]];

                                            let nullOption = [
                                                { value: 0, label: "Не задан" }
                                            ];

                                            let activeProduct = element.product ? element.product.name : 0
                                            let arrProducts = [{ value: 0, label: "Не задан" }]

                                            Object.keys(element.products).map((itm) => {
                                                arrProducts.push({ value: element.products[itm].num, label: element.products[itm].name })
                                            })

                                            let activeStage = element.stage ? element.stage.name : 0
                                            let arrStages = [{ value: 0, label: "Не задан" }]

                                            let currStagesList = [];

                                            Object.keys(productsEl.stages).map((item) => {
                                                arrStages.push({ value: productsEl.stages[item].num, label: productsEl.stages[item].name })
                                            })

                                            currData.push({
                                                task_list_id: +element.task_list_id,
                                                project_id: +element.project_id,
                                                product_id: element.product ? +element.product.id : 0,
                                                stage_id: element.stage ? +element.stage.id : 0,
                                                department: element.department
                                            })

                                            // const changeSelect = (e) => {
                                            //     console.log(e.value)

                                            //     if (e.value === 0) {
                                            //         setFlagNullOption(true);
                                            //         currStagesList = nullOption;
                                            //     } else {
                                            //         setFlagNullOption(false);
                                            //         currStagesList = arrStages;
                                            //     }

                                            //     console.log(currStagesList)
                                            // }


                                            return <tr className="tr-request" key={element.name}>
                                                <td>
                                                    <input type="hidden" name="task_list_id" value={element.task_list_id} />
                                                    <input type="hidden" name="project_id" value={element.project_id} />
                                                    <input type="hidden" name="department" value={element.department} />

                                                    {element.client}
                                                </td>
                                                <td>{element.project}</td>
                                                <td>{element.name}</td>
                                                <td>{element.product !== null ?

                                                    <div className="content-select">
                                                        <ReactSelect onChange={changeSelectProduct} defaultValue={arrProducts.find(obj => obj.label === activeProduct)} options={arrProducts} name="product_id" className="react-select-container" classNamePrefix="react-select" />
                                                    </div>
                                                    : <div className="content-select">
                                                        <ReactSelect onChange={changeSelectProduct} defaultValue={arrProducts[0]} options={arrProducts} name="product_id" className="react-select-container" classNamePrefix="react-select" />
                                                    </div>}</td>
                                                <td>{element.stage !== null ?
                                                    <div className="content-select">
                                                        <ReactSelect onChange={changeSelect} defaultValue={arrStages.find(obj => obj.label === activeStage)} options={arrStages} name="stage_id" className="react-select-container" classNamePrefix="react-select" />
                                                    </div>
                                                    : <div className="content-select">
                                                        <ReactSelect onChange={changeSelect} defaultValue={arrStages[0]} options={arrStages} name="stage_id" className="react-select-container" classNamePrefix="react-select" />
                                                    </div>}</td>




                                                {/* <td>{element.product !== null ? 
                                            
                                            <div className="content-select">
                                                <ReactSelect onChange={changeSelect} defaultValue={arrProducts.find(obj => obj.label === activeProduct)} options={arrProducts} name="product_id" className="react-select-container" classNamePrefix="react-select" />
                                            </div>
                                            : <div className="content-select">
                                                <ReactSelect onChange={changeSelect} defaultValue={arrProducts[0]} options={arrProducts} name="product_id" className="react-select-container" classNamePrefix="react-select" />
                                            </div>}</td>
                                            <td>{element.stage !== null ? 
                                            <div className="content-select">
                                                <ReactSelect defaultValue={flagNullOption ? nullOption[0] : arrStages.find(obj => obj.label === activeStage)} options={flagNullOption ? nullOption : arrStages} name="stage_id" className="react-select-container" classNamePrefix="react-select" />
                                            </div> 
                                            : <div className="content-select">
                                                <ReactSelect defaultValue={flagNullOption ?nullOption[0] : arrStages[0]} options={flagNullOption ? nullOption : arrStages} name="stage_id" className="react-select-container" classNamePrefix="react-select" />
                                            </div>}</td> */}
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                listProductsStages.length != 0 &&
                                <button type="submit" disabled={disableBtn} className="btn-white">Сохранить</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NominateProduct