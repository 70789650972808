import { React, useState, useEffect } from 'react';
import useStickyState from '../../assets/js/useStickyState';
import Header from '../../components/Header/Header';
import styles from './UnloadTime.scss';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import spinner from '../../assets/img/spinner.svg';
import { useNavigate } from 'react-router-dom';

const UnloadTime = ({ changeTheme, themeBlack }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [countEntry, setCountEntry] = useState(null);
  const [uploadingData, setUploadingData] = useState('');

  const getData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/time-records-update/db`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      });

      if (data) {
        setIsLoading(false);
        console.log('Data ', data);
        setUploadingData(data);
        setCountEntry(data.updated);
      }

    } catch (error) {
      setIsLoading(false);
      console.log('error');
    }
  }

  return (
    <div>
      <Helmet>
        <title>Выгрузка времени в Google (БД)</title>
      </Helmet>
      <div className="content content-upload">
        {isLoading &&
          <div className="layout-fixed">
            <h2>Выгрузка выполняется...</h2>
            <img className="loading" src={spinner} alt="Spinner" />
          </div>
        }
        <div className="container">
          <h1>Выгрузка времени в Google (БД)</h1>

          <div className="upload-btns">
            <a href="#" onClick={getData} className='btn-white'>Экспорт в Google</a>
            {uploadingData &&
              <span>Экспортировано {countEntry} записей.</span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnloadTime