import React from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Footer.scss';
import footerIimg from '../../assets/img/footer-img.jpg';

const Footer = (hideFooterImg, hideFooterContent) => {
    let location = useLocation();
    hideFooterImg = location.pathname === '/404' || location.pathname === '/plan-result' || location.pathname === '/payments' ||
    location.pathname === '/acts' || location.pathname === '/plan-rd' || location.pathname === '/plan-dc' ||
    location.pathname === '/receipts' || location.pathname === '/nondev-payments' ? true : false;
    hideFooterContent = location.pathname === '/' || location.pathname === '/auth' ? false : true;

    return (
        <footer className={!hideFooterContent ? '' : 'footer-small'}>
            <div className="container">
                {
                    !hideFooterContent &&
                    <div>
                        <div className="footer-title">CreativePeople online</div>
                        <div className="footer-elems">
                            <div className="footer-elem">
                                <div className="footer-elem-title">Мы в соцсетях</div>
                                <div className="footer-elem-links">
                                    <a href="https://t.me/cpeople_ru" target="_blank">Telegram</a>
                                    <a href="https://vk.com/cpeople" target="_blank">ВКонтакте</a>
                                    <a href="https://www.behance.net/cpeople" target="_blank">Behance</a>
                                    <a href="https://vc.ru/u/540358-creativepeople" target="_blank">VC.RU</a>
                                </div>
                            </div>

                            <div className="footer-elem">
                                <div className="footer-elem-title">Наши сайты</div>
                                <div className="footer-elem-links">
                                    <a href="https://cpeople.ru" target="_blank">cpeople.ru</a>
                                    <a href="https://nuts.digital/" target="_blank">nuts.digital</a>
                                    <a href="https://partners.cpeople.ru" target="_blank">partners.cpeople.ru</a>
                                    <a href="https://hr.cpeople.ru" target="_blank">hr.cpeople.ru</a>
                                    <a href="https://realty.cpeople.ru" target="_blank">realty.cpeople.ru</a>
                                </div>
                            </div>

                            <div className="footer-elem">
                                <div className="footer-elem-title">Партнеры</div>
                                <div className="footer-elem-links">
                                    <a href="https://kirarock.team" target="_blank">kirarock.team</a>
                                    <a href="https://humbleteam.com" target="_blank">humbleteam.com</a>
                                    <a href="https://wolfenfox.ru" target="_blank">wolfenfox.ru</a>
                                </div>
                            </div>
                            {location.pathname != '/auth' &&
                                <div className="footer-elem-right">
                                    <div className="footer-elem-desc">Если вам чего-то не хватает на этом сайте, вы можете заполнить форму обратной связи. Сделаем все, что в наших силах, чтобы этот сайт был еще лучше и удобнее.</div>
                                    <a href="https://forms.gle/camoKg2h8TrXLUnGA" className="btn-white" target="_blank">Предложить сервис</a>
                                </div>
                            }
                        </div>
                    </div>
                }

                {!hideFooterImg && <img className="footer-img" src={footerIimg} alt="" />}

            </div>
        </footer>
    )
}

export default Footer