import React from 'react'
import { useState } from 'react';
import ReactSelect from 'react-select'
import StageSmall from '../StageSmall/StageSmall';
import closeGreen from '../../assets/img/close-green.svg'

const typeWork = [
    { value: 'Аудит', label: "Аудит" },
    { value: 'Брендинг', label: "Брендинг" },
    { value: 'Видео', label: "Видео" },
    { value: 'Иллюстрации', label: "Иллюстрации" },
    { value: 'Интернет-магазин', label: "Интернет-магазин" },
    { value: 'Корпоративный сайт', label: "Корпоративный сайт" },
    { value: 'Креативная концепция', label: "Креативная концепция" },
    { value: 'Мобильное приложение', label: "Мобильное приложение" },
    { value: 'Печатная продукция', label: "Печатная продукция" },
    { value: 'Поддержка', label: "Поддержка" },
    { value: 'Промо-сайт / Лендинг', label: "Промо-сайт / Лендинг" },
    { value: 'Сайт на Тильде', label: "Сайт на Тильде" },
    { value: 'Сайт объекта недвижимости', label: "Сайт объекта недвижимости" },
    { value: 'Сайт с каталогом', label: "Сайт с каталогом" },
    { value: 'Сайт-сервис', label: "Сайт-сервис" },
    { value: 'Прочее', label: "Прочее" },
]


const Product = ({num, chgSteps, projectNum, removeProduct, data}) => {
    const [itemsStage, setItemsStage] = useState([""]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const productsArray = Object.entries(data).map(([key, item]) => ({ id: key, value: item.title, label: item.title }));

    const resultStage = itemsStage.map((element, index) => {
        return <StageSmall id={index+1 + '1'+ num} key={index} chgSteps={chgSteps} remove={remove}
                num={index+1} name={index+=1} projectNum={num} stagesData={selectedProducts}/>;
    });

    function addStage(index) {
        setItemsStage([...itemsStage, index.target.value]);
    }

    function remove(index) {
        setItemsStage([...itemsStage.slice(0, index), ...itemsStage.slice(index + 1)]);
    }

    function changeProduct(prod) {
        setSelectedProducts(Object.entries(data[prod.id].stages).map(([key, item]) => ({ id: key, value: item.title, label: item.title })));
    }

    return (
        <div className="step-wrap">
            {num != 1 && <div onClick={() => removeProduct(num-1)} id={num} className="delete-step">Удалить продукт <i><img src={closeGreen} alt="" /></i></div>}
            <div className="title-step">Продукт {num}</div>
            <div className="select-box">
                <div className="content-select">
                    <ReactSelect options={productsArray} className="react-select-container" onChange={changeProduct}
                    classNamePrefix="react-select" name={'projects-' + num + '_name'} placeholder='Выберите из списка' />
                </div>
            </div>

            {resultStage}

            <button type="button" onClick={addStage} className="btn-white">Добавить этап</button>

            <hr />
        </div>
    )
}

export default Product