import {React, useState, useEffect} from 'react'
import ReactSelect from 'react-select'
import useStickyState from '../../assets/js/useStickyState'
import Footer from '../Footer/Footer'

import Header from '../Header/Header'
import styles from './Signature.scss'
import { v4 as uuidv4 } from 'uuid'
import copy from 'copy-html-to-clipboard'
import ControlInput from './ControlInput'
import ControlCheckbox from './ControlCheckbox'
import ControlCheckboxList from './ControlCheckboxList'
import ControlInputListExpandable from './ControlInputListExpandable'
import {inputRequired, inputArrayRequired, checkboxRequired, inputPhoneRequired, inputPhone} from './validationRules'

const Signature = ({changeTheme, themeBlack}) => {

  const [formState, setformState] = useState({
    name: {
      id: uuidv4(),
      value: ''
    },
    positions: [
      {
        id: uuidv4(),
        value: ''
      }
    ],
    phone: {
      id: uuidv4(),
      value: ''
    },
    phoneOffice: {
      id: uuidv4(),
      value: '+7 (495) 917-72-58',
      checked: false
    },
    sites: [
      {
        id: uuidv4(),
        value: 'www.cpeople.ru',
        label: 'www.cpeople.ru',
        checked: true
      },
      {
        id: uuidv4(),
        value: 'hr.cpeople.ru',
        label: 'hr.cpeople.ru',
        checked: false
      },
      {
        id: uuidv4(),
        value: 'partners.cpeople.ru',
        label: 'partners.cpeople.ru',
        checked: false
      }
    ],
    shortened: {
      id: uuidv4(),
      value: 'on',
      checked: false
    }
  })

  function handleControlChange(controlEvent) {
      const {name, value} = controlEvent

      setformState({
          ...formState,
          [name]: value
      })
  }


  const [copyed, setCopyed] = useState(false)

  function handleSubmit(e) {
      e.preventDefault()

      if(!validateAll()) return

      document.body.classList.add('copyMode')
      const signValue = copy(document.querySelector('#copy').outerHTML, {asHtml: true})
      document.body.classList.remove('copyMode')

      if (signValue) {
          setCopyed(true)
          setTimeout(() => {
              setCopyed(false)
          }, 2000)
      }
  }


  const [errors, setErrors] = useState({})
  const validationRules = {
      name: [inputRequired],
      positions: [inputArrayRequired],
      phone: [inputPhoneRequired]
  }

  function validateAll () {
      let allErrors = {}

      Object.keys(validationRules).forEach(name => {
          allErrors = {
              ...allErrors,
              [name]: validationRules[name].map(validate => validate(formState[name])).filter(val => val)[0] || null
          }
      })
      setErrors(allErrors)
      return !Object.values(allErrors).some(value => value)
  }

  function validateByName (name) {
      setErrors({
          ...errors,
          [name]: validationRules[name].map(validate => validate(formState[name])).filter(val => val)[0] || null
      })
  }


  return (
    <div>
        <div className='content content-signature'>
            <div className="container">
              <div className="content-head">
                <h1>Форма создания подписи</h1>
                <div className="desc">Все поля помеченные * обязательны для заполнения</div>
              </div>
                <form action="" onSubmit={handleSubmit}>
                  <div className="content-form-item">
                    <div className="content-form-item-desc">
                      <label htmlFor="">Имя и фамилия <i>*</i></label>
                    </div>

                    <ControlInput
                        placeholder="Сначала имя, затем фамилия"
                        id={formState.name.id}
                        className={errors['name'] && "is-invalid"}
                        name="name"
                        value={formState.name.value}
                        onChange={handleControlChange}
                        onBlur={() => validateByName('name')}
                    />
                  </div>

                  <hr />

                  <div className="content-form-item">
                    <div className="content-form-item-desc">
                      <label htmlFor="">Должность <i>*</i></label>
                      <p>Должность можно вводить и по-русски
                      и по-английски. Можно задать несколько должностей одновременно.
                      <br />
                      <b>Пример:</b> Head of Research & Development department</p>
                    </div>

                      <ControlInputListExpandable
                          className={errors['positions'] && "is-invalid"}
                          name="positions"
                          list={formState.positions}
                          onChange={handleControlChange}
                          onBlur={() => validateByName('positions')}
                      />
                  </div>

                  <hr />

                  <div className="content-form-item">
                    <div className="content-form-item-desc">
                      <label htmlFor="">Мобильный <i>*</i></label>
                    </div>

                    <div className="signature-phones">
                      <ControlInput
                        placeholder='+7 (___) ___-__-__'
                        id={formState.phone.id}
                        className={`input-tel ${errors['phone'] && "is-invalid"}`}
                        name="phone"
                        mask="+7 (999) 999-99-99"
                        value={formState.phone.value}
                        onChange={handleControlChange}
                        onBlur={() => validateByName('phone')}
                      />

                      <ControlCheckbox
                        id={formState.phoneOffice.id}
                        className={`add-office-phone`}
                        name="phoneOffice"
                        label="Добавлять телефон офиса:"
                        value={formState.phoneOffice.value}
                        checked={formState.phoneOffice.checked}
                        onChange={handleControlChange}
                      />
                    </div>
                  </div>

                  <hr />

                  <div className="content-form-item">
                    <div className="content-form-item-desc">
                      <label htmlFor="">Сайты  <i>*</i></label>
                    </div>
                      <ControlCheckboxList
                        name="sites"
                        className='add-sites'
                        list={formState.sites}
                        onChange={handleControlChange}
                      />
                  </div>

                  <hr />

                  <h2>Ваша подпись</h2>

                  <div className="total-signature-wrap">
                    <div id="copy" className="total-signature-box">
                      <div className="signature-main">
                        <span>—</span><br/>
                        <span>{formState.name.value}</span>
                        {formState.positions.map((position, i) =>
                          <><br/><span key={i}>{position.value}</span></>
                        )}
                      </div>
                      <br/>
                      <div className="department">
                        <span>Digital experience agency CreativePeople</span>
                        {!formState.shortened.checked && <>
                            <br/><span>Product Research & Development</span>
                            <br/><span>Digital & Creative Services</span>
                          </>
                        }
                      </div>
                      <br/>
                      <div className="phones">
                        {!inputPhone({value: formState.phone.value}) && <a href={'tel:' + formState.phone.value}>{formState.phone.value}</a>}
                        {!inputPhone({value: formState.phone.value}) && formState.phoneOffice.checked && <br/>}
                        {formState.phoneOffice.checked && <a href={'tel:' + formState.phoneOffice.value}>{formState.phoneOffice.value}</a>}
                      </div>

                      <div className="sites">{
                        formState.sites.filter(site => site.checked).map((site, i) =>
                          <>{i != 0 && <>|&nbsp;</>}<a href={'http://' + site.value} key={i}>{site.value}</a></>
                        )
                      }</div>
                    </div>

                    <div className="signature-bottom">
                      <button type="submit" className="btn-white">
                          {copyed ? 'Скопировали!' : 'Скопировать подпись'}
                      </button>

                      <ControlCheckbox
                        id={formState.shortened.id}
                        className={`add-sites`}
                        name="shortened"
                        label="Сокращенная подпись"
                        value={formState.shortened.value}
                        checked={formState.shortened.checked}
                        onChange={handleControlChange}
                      />
                    </div>
                  </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Signature