import React from 'react'

function LabelRadioBtn(props) {
    return (

        <label className='label-radio-btns'>
            <input type="radio" required={props.required} onChange={props.changeLabelRadioBtn} name={props.name} value={props.value} />
            <span>{props.title}</span>
        </label>
    )
}

export default LabelRadioBtn