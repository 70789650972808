import React, { useState, useEffect } from "react"
import UseCreationData from "../../api/UseCreationData"

import RadioBtnsBlock from "../../components/RadioBtnsBlock/RadioBtnsBlock";
import Datepicker from "../../components/Datepicker/Datepicker";
import Stage from "../../components/Stage/Stage";

import closeGreen from "../../assets/img/close-green.svg"
import clear from "../../assets/img/clear.svg"

const Application = ({ id, remove, input, department, applications, applicationsDepartment, taskForm,
                        showLabelRadio, showLabelRadioBtn, showLabelCheckbox, changeLabelRadio,
                        chgDate, applicationInput, applicationsInGroups, showApplicationsGroups, setErrors }) => {
    // Этапы работы (Новый договор, приложение)
    const [itemsStage, setItemsStage] = useState([""]);
    const chgSteps = function (event, stageNumber) {
        let stagesArr = input[`stages${id}`] || [];
        let stageInfo = stagesArr[stageNumber] || {};

        stageInfo[event.target.name] = event.target.value;
        stagesArr[stageNumber] = stageInfo;

        setErrors(event.target.value, event.target.name)
        applicationInput(`stages${id}`, stagesArr);
    }
    function addStage(index) {
        setItemsStage([...itemsStage, index.target.value]);
    }
    function removeStage(index) {
        setItemsStage([...itemsStage.slice(0, index), ...itemsStage.slice(index + 1)]);
    }
    const resultStages = itemsStage.map((element, index) => {
        return <Stage id={index + 1} appId={id} key={index} chgSteps={e => chgSteps(e, index)} remove={removeStage} />;
    });

    return (
        <>
            {/*Какой отдел будет выполнять/заказывать работу по приложению?*/}
            <div className="content-form-item">
                <div className="content-form-item-desc">
                    <h2>Приложение {id}</h2>
                    {id !== 1 && <div onClick={() => remove(id - 1)} id={id} className="delete-step">Удалить приложение <i><img src={closeGreen} alt="" /></i></div>}
                    <label htmlFor="">{department.title} {department.required && <i>*</i>}</label>
                </div>

                <div className="label-radio-btns-wrap" data-required={department.required ? true : false}>
                    {showLabelRadioBtn(department, `apps-${id}_department`, `department${id}`)}
                </div>
            </div>
            <hr />

            {   //Предмет приложения
                input[`department${id}`] != null && input[`department${id}`] != "" &&
                <>
                    <div className="content-form-item">
                        <div className="content-form-item-desc">
                            <label htmlFor="">
                                {applications[`application-subject-${input[`department${id}Id`]}`].title} {applications[`application-subject-${input[`department${id}Id`]}`].required && <i>*</i>}
                            </label>
                        </div>

                        <div className="whats-do-wrap" data-required={applications[`application-subject-${input[`department${id}Id`]}`].required ? true : false}>
                            <div className="whats-do-list">
                                {
                                    showApplicationsGroups(applications, applicationsInGroups(applicationsDepartment[input[`department${id}Id`]]),
                                        input[`department${id}Id`], `application-subject`, `apps-${id}_`, `applicationSubject${id}`)
                                }

                                {
                                    (input[`applicationSubject${id}`] === "Другое" ||
                                        (input[`department${id}Id`] === "development" && input[`applicationSubject${id}Id`] === "asd35") ||
                                        (input[`department${id}Id`] === "digital" && input[`applicationSubject${id}Id`] === "asd11"))
                                    &&
                                    <div className="other-input">
                                        <input onChange={() => applicationInput(`applicationSubject${id}Other`, "Другое")}
                                            name={`apps-${id}_application-subject-${input[`department${id}Id`]}-other`} type="text" placeholder="Другое" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <hr />
                </>
            }

            {   //Дата начала первого отчетного периода
                input[`applicationSubject${id}`] != null && input[`applicationSubject${id}`] != "" &&
                ((input[`department${id}Id`] === "development" &&
                    (input[`applicationSubject${id}Id`] === "asd15" || input[`applicationSubject${id}Id`] === "asd23" || input[`applicationSubject${id}Id`] === "asd34")) ||
                    (input[`department${id}Id`] === "digital" &&
                        (input[`applicationSubject${id}Id`] === "asd4" || input[`applicationSubject${id}Id`] === "asd5")))
                &&
                <>
                    <div className="content-form-item">
                        <div className="content-form-item-desc">
                            <label htmlFor="">{applications[`application-subject-${input[`department${id}Id`]}-monthly`].title} <i>*</i></label>
                        </div>

                        <div className="content-select" data-required={true}>
                            <Datepicker name={`apps-${id}_application-subject-${input[`department${id}Id`]}-monthly`} inputName={`applicationSubjectMonthly${id}`}
                                chgDate={chgDate} />
                        </div>
                    </div>
                    <hr />
                </>
            }

            {   //В каком виде будет Задание?
                input[`applicationSubject${id}`] != null && input[`applicationSubject${id}`] != "" &&
                <>
                    <div className="content-form-item">
                        <div className="content-form-item-desc">
                            <label htmlFor="">{taskForm.title} {taskForm.required && <i>*</i>}</label>
                        </div>

                        <div className="content-form-item-right" data-required={taskForm.required ? true : false}>
                            {showLabelRadio(taskForm, `apps-${id}_task-form`, `taskForm${id}`)}
                        </div>
                    </div>
                    <hr />
                </>
            }

            {   //Этапы
                input[`taskForm${id}`] != null && input[`taskForm${id}`] != "" &&
                <>
                    <div className="content-form-item content-form-item--full-width">
                        <div className="content-form-item-desc">
                            <label htmlFor="">Этапы <i>*</i></label>
                        </div>

                        <div className="steps" data-required={true}>
                            {resultStages}
                            <button type="button" onClick={addStage} className="btn-white">Добавить этап</button>
                        </div>
                    </div>
                    <hr />

                    {   //Будет ли закрываться каждый этап актом
                        input[`stages${id}`] != null && input[`stages${id}`][0] != null && Object.keys(input[`stages${id}`][0]).length >= 3 &&
                        <>
                            <div className="content-form-item content-form-item--full-width">
                                <div className="content-form-item-desc">
                                    <label htmlFor="">{applications["closed-one-act"].title} {applications["closed-one-act"].required && <i>*</i>}</label>
                                </div>

                                <div className="whats-do-wrap" data-required={applications["closed-one-act"].required ? true : false}>
                                    <div className="whats-do-list">
                                        {showLabelRadio(applications["closed-one-act"], `apps-${id}_closed-one-act`, `closedOneAct${id}`)}
                                    </div>

                                    {
                                        (input[`closedOneAct${id}`] === "coa3" || input[`closedOneAct${id}`] === "Другое") &&
                                        <div className="whats-do-adds">
                                            <input onChange={(e) => applicationInput(`closedOneActOther${id}`, e.target.value)} name={`apps-${id}_closed-one-act-other`} type="text"
                                                placeholder="Другое" />
                                        </div>
                                    }
                                </div>
                            </div>
                            <hr />
                        </>
                    }

                    {   //Последовательность выполнения работ
                        ((input[`closedOneAct${id}`] != null && input[`closedOneAct${id}`] != "" && input[`closedOneAct${id}`] != "Другое") ||
                            (input[`closedOneAct${id}`] != null && input[`closedOneAct${id}`] != "" && input[`closedOneAct${id}`] === "Другое" &&
                            input[`closedOneActOther${id}`] != null && input[`closedOneActOther${id}`].length > 0))
                        &&
                        <>
                            <div className="content-form-item content-form-item--full-width">
                                <div className="content-form-item-desc">
                                    <label htmlFor="">{applications["work-sequence"].title} {applications["work-sequence"].required && <i>*</i>}</label>
                                </div>

                                <div className="whats-do-wrap" data-required={applications["work-sequence"].required ? true : false}>
                                    <div className="whats-do-list">
                                        {showLabelRadio(applications["work-sequence"], `apps-${id}_work-sequence`, `workSequence${id}`)}
                                    </div>

                                    {
                                        input[`workSequence${id}Id`] === "ws2" &&
                                        <div className="whats-do-adds">
                                            <input onChange={(e) => applicationInput(`workSequenceExecution${id}`, e.target.value)}
                                                name={`apps-${id}_work-sequence-execution`}
                                                type="text" placeholder="Последовательность выполнения" />
                                        </div>
                                    }
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                </>
            }

            {   //Есть ли информационные материалы?
                input[`workSequence${id}`] != null && input[`workSequence${id}`] != "" &&
                <>
                    <div className="content-form-item content-form-item--full-width">
                        <div className="content-form-item-desc">
                            <label htmlFor="">{applications["materials-exists"].title} {applications["materials-exists"].required && <i>*</i>}</label>
                            <div dangerouslySetInnerHTML={{ __html: applications["materials-exists"].hint }}></div>
                        </div>

                        <div className="whats-do-wrap">
                            <div className="labels" data-required={applications["materials-exists"].required ? true : false}>
                                {showLabelRadio(applications["materials-exists"], `apps-${id}_materials-exists`, `materialsExists${id}`)}
                            </div>

                            {
                                (input[`materialsExists${id}Id`] === "me1" || input[`materialsExists${id}Id`] === "me2") &&
                                <div className="adds" data-required={applications["materials-list"].required ? true : false}>
                                    <b>{applications["materials-list"].title}</b>
                                    <span>Выберите один или несколько вариантов</span>
                                    <div className="checkboxs">
                                        {showLabelCheckbox(applications["materials-list"], `apps-${id}_materials-list[]`, `materialsList${id}`)}
                                    </div>

                                    {   //Наименования, формат и объем/количество материалов
                                        input[`materialsList${id}`] != null && input[`materialsList${id}`].includes("ml12") &&
                                        <div className="textarea-block">
                                            <textarea onChange={(e) => applicationInput(`materialsListTotal${id}`, e.target.value )}
                                                name={`apps-${id}_materials-list-total`} placeholder="Наименования, формат и объем/количество материалов">
                                            </textarea>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <hr />
                </>
            }

            {   //Схема оплаты
                input[`materialsExists${id}`] != null && input[`materialsExists${id}`] != "" &&
                <>
                    <div className="content-form-item content-form-item--full-width">
                        <div className="content-form-item-desc">
                            <label htmlFor="">{applications["payment-scheme"].title} {applications["payment-scheme"].required && <i>*</i>}</label>
                        </div>

                        <div className="label-radio-btns-wrap" data-required={applications["payment-scheme"].required ? true : false}>
                            {showLabelRadioBtn(applications["payment-scheme"], `apps-${id}_payment-scheme`, `paymentScheme${id}`)}
                        </div>
                        {
                            (input[`paymentScheme${id}`] === "Другое" || input[`paymentScheme${id}Id`] === "ps4") &&
                            <div className="whats-do-wrap">
                                <div className="add-input">
                                    <br />
                                    <input onChange={() => applicationInput(`paymentSchemeOther${id}`, "Другое")} name={`apps-${id}_payment-scheme-other`}
                                        type="text" placeholder="Другое" />
                                </div>
                            </div>
                        }
                    </div>
                    <hr />
                </>
            }

            {   //Дополнительные договоренности / комментарии
                input[`paymentScheme${id}`] != null && input[`paymentScheme${id}`] != ""
                &&
                <>
                    <div className="content-form-item content-form-item--full-width" style={{marginBottom:"32px"}}>
                        <div className="content-form-item-desc">
                            <label htmlFor="">{applications["additional-comments"].title} {applications["additional-comments"].required && <i>*</i>}</label>
                        </div>

                        <div className="textarea-block" data-required={applications["additional-comments"].required ? true : false}>
                            <textarea name={`apps-${id}_additional-comments`} placeholder="Введите сообщение…"
                                onChange={(e) => applicationInput(`additionalComments${id}`, e.target.value)} >
                            </textarea>
                        </div>
                    </div>
                </>
            }
        </>)
}

export default Application